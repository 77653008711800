/* eslint-disable no-constant-condition */
/* eslint-disable no-console */
import { useState, useEffect } from "react";
import {
  Grid,
  Switch,
  FormControlLabel,
  Menu,
  MenuItem,
  IconButton
} from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { Icon } from "@iconify/react";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import { httprequest } from "../../../data/api";
import Button from "../../../components/Button";
import { toast, ToastContainer } from "react-toastify";
import useValidator from "../../../hooks/useValidator";
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import useStyles from "../styles";
import { useUserState } from "../../../context/UserContext";

const TEACHER_CLASS_ENDPOINT = "/api/v1/teacher/classes";
const TEACHER_SUBJECT_ENDPOINT = "/api/v1/teacher/subjects";
const ADMIN_CLASS_ENDPOINT = "/api/v1/class";
const ADMIN_SUBJECT_ENDPOINT = "/api/v1/subjects";
const ASSIGNMENT_ENDPOINT = "/api/v1/assignment";

const CreateAssignment = () => {
  // const [file, setFile] = useState();
  // const [select, setSelect] = useState("");
  const styles = useStyles();
  const { userRole } = useUserState();
  const [classData, setClassData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [currentAnchor, setCurrentAnchor] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [assignmentValidator, showAssignmentValidator] = useValidator();

  const [questionArr, setQuestionArr] = useState([
    {
      question: "",
      question_index: 1,
      type: "",
      grade_point: "",
      answer: "",
      required: false,
      options: [""]
    }
  ]);
  const [assignmentForm, setAssignmentForm] = useState({
    subject_id: "",
    title: "",
    class_id: "",
    submission_date: "",
    description: "",
    total_grade: 0,
    instruction: "",
    // --| This is to append question_index to each object on the arr
    questions: [
      questionArr?.map((que, index) => {
        que.question_index = index + 1;

        return {
          que
        };
      })
    ]
  });

  // This handles the Assignment Description input
  const handleAssignmentChange = (e) => {
    const { name, value } = e.target;

    setAssignmentForm({ ...assignmentForm, [name]: value });
  };

  const handlePostAssignment = (status) => {
    if (assignmentValidator.allValid()) {
      setIsCreating(true);
      httprequest
        .post(ASSIGNMENT_ENDPOINT, { ...assignmentForm, status })
        .then((res) => {
          setIsCreating(false);
          toast.success(res?.data?.message);
          window.location.reload();
        })
        .catch((err) => {
          setIsCreating(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showAssignmentValidator(true);
    }
  };

  const handleCreateAssignment = () => {
    handlePostAssignment("published");
  };

  const handleClick = (event, queInd) => {
    setAnchorEl(event.currentTarget);
    setCurrentAnchor(queInd);
  };
  const handleAdditionalQueClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * @param {event parameter passed on handleChange} e
   * @param {*} index
   * @param {*} queInd
   */
  const handleMultipleChoiceChange = (e, index, queInd) => {
    const { value } = e.target;

    const options = questionArr[queInd]["options"];
    const newOptionArr = [...options];

    newOptionArr[index] = value;
    const newQueArr = [...questionArr];
    newQueArr[queInd]["options"] = newOptionArr;
    setAssignmentForm({ ...assignmentForm, questions: newQueArr });
  };

  /**
   *
   * @param {event parameter passed on handleChange} e
   * @param {*} index
   * @param {*} queInd
   */
  const handleCorrectChoiceChange = (e, index, queInd) => {
    const { value, checked, type } = e.target;

    if (type === "radio") {
      const newQuestionArr = [...questionArr];
      newQuestionArr[queInd]["answer"] = [value];
      setQuestionArr(newQuestionArr);
      setAssignmentForm({ ...assignmentForm, questions: newQuestionArr });
    } else if (type === "checkbox") {
      if (checked) {
        const answers = questionArr[queInd]["answer"];
        const newAnswersArr = [...answers];
        newAnswersArr[index] = value;
        const newQueArr = [...questionArr];
        newQueArr[queInd]["answer"] = newAnswersArr;
        setAssignmentForm({ ...assignmentForm, questions: newQueArr });
      } else {
        const newQueArr = [...questionArr];
        const newAnswerArr = newQueArr[queInd]["answer"]?.filter(
          (_item, ind) => ind !== index
        );
        newQueArr[queInd]["answer"] = newAnswerArr;
        setQuestionArr(newQueArr);
        setAssignmentForm({ ...assignmentForm, questions: newQueArr });
      }
    }
  };

  // This Updates the value a specific question based on user input.
  const handleQueChange = (e, index) => {
    const { name, value } = e.target;
    const newQuestionArr = [...questionArr];
    newQuestionArr[index][name] = value;
    setQuestionArr(newQuestionArr);
    setAssignmentForm({ ...assignmentForm, questions: newQuestionArr });
  };

  const toggleQueDescriptionTrue = () => {
    const newQuestionArr = [...questionArr];
    newQuestionArr[currentAnchor]["description"] = "";
    setQuestionArr(newQuestionArr);
    setAssignmentForm({ ...assignmentForm, questions: newQuestionArr });
  };

  const removeQueDescription = (index) => {
    const newQuestionArr = [...questionArr];
    delete newQuestionArr[index]["description"];
    setQuestionArr(newQuestionArr);
    setAssignmentForm({ ...assignmentForm, questions: newQuestionArr });
  };

  // This Updates the value on the required field on each question.
  const handleReqQueChange = (e, index) => {
    const { name, checked } = e.target;
    const newQuestionArr = [...questionArr];
    newQuestionArr[index][name] = checked;
    setQuestionArr(newQuestionArr);
    setAssignmentForm({ ...assignmentForm, questions: newQuestionArr });
  };

  // --| Add additional option on each question
  const handleAdditionalOptions = (queInd) => {
    const newQueArr = [...questionArr];
    const newRadioArr = [...questionArr[queInd]["options"], [" "]];
    newQueArr[queInd]["options"] = newRadioArr;
    setQuestionArr(newQueArr);
    setAssignmentForm({ ...assignmentForm, questions: newQueArr });
  };

  // This creates a new question
  const handleAdditionalQuestion = () => {
    const newQue = {
      question: "",
      question_index: "",
      type: "",
      answer: "",
      required: false,
      grade_point: "",
      options: [""]
    };

    setQuestionArr([...questionArr, newQue]);
  };

  // --| Remove Additional Question that has been added
  const removeAdditionalQue = (index) => {
    const newQueArr = questionArr.filter((_item, ind) => ind !== index);
    setQuestionArr(newQueArr);
  };

  // --| Remove additional choice added as an option in a question
  const removeAdditionalChoice = (index, queInd) => {
    const newQueArr = [...questionArr];
    const newChoiceArr = newQueArr[queInd]["options"]?.filter(
      (_item, ind) => ind !== index
    );
    newQueArr[queInd]["options"] = newChoiceArr;
    setQuestionArr(newQueArr);
    setAssignmentForm({ ...assignmentForm, questions: newQueArr });
  };

  // --| Get all my classes as a SUBJECT teacher
  const getAllTeacherClasses = () => {
    httprequest
      .get(TEACHER_CLASS_ENDPOINT)
      .then((res) => {
        setClassData(res?.data?.data);
      })
      .catch(() => {});
  };

  // --| Get all classes as a school admin
  const getAllAdminClasses = () => {
    httprequest
      .get(ADMIN_CLASS_ENDPOINT)
      .then((res) => {
        setClassData(res?.data?.data);
      })
      .catch(() => {});
  };

  // --| Get all my Subject as a SUBJECT teacher
  const getAllTeacherSubjects = () => {
    httprequest
      .get(TEACHER_SUBJECT_ENDPOINT)
      .then((res) => {
        setSubjectData(res?.data?.data);
      })
      .catch(() => {});
  };

  // --| Get all Subjects as a School Admin
  const getAllAdminSubjects = () => {
    httprequest
      .get(ADMIN_SUBJECT_ENDPOINT)
      .then((res) => {
        setSubjectData(res?.data?.data);
      })
      .catch(() => {});
  };

  const options = [
    {
      value: "paragraph",
      text: "Paragraph",
      icon: <Icon icon="carbon:text-short-paragraph" width="25" height="25" />
    },
    {
      value: "multiple_choice",
      text: "Multiple Choice",
      icon: <Icon icon="mdi:radiobox-marked" width="25" height="25" />
    },
    {
      value: "checkbox",
      text: "Checkbox",
      icon: <Icon icon="ci:checkbox-check" width="25" height="25" />
    },
    {
      value: "dropdown",
      text: "Dropdown",
      icon: <Icon icon="tabler:select" width="25" height="25" />
    },
    {
      value: "file_upload",
      text: "File Upload",
      icon: <Icon icon="basil:cloud-upload-outline" width="25" height="25" />
    },
    {
      value: "date",
      text: "Date",
      icon: (
        <Icon
          icon="material-symbols-light:date-range-sharp"
          width="25"
          height="25"
        />
      )
    },
    {
      value: "time",
      text: "Time",
      icon: <Icon icon="mingcute:time-line" width="25" height="25" />
    }
  ];

  useEffect(() => {
    if (userRole?.role === "school_admin") {
      getAllAdminClasses();
      getAllAdminSubjects();
    } else {
      getAllTeacherClasses();
      getAllTeacherSubjects();
    }
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/assesments/assignments"
                link_one_name="Assignments"
                active="Create Assignment"
                description="Create a new assignment"
              />
            </div>
          </div>
          <div className="page-content">
            <div className={styles.assignmentcontainerform}>
              <div className="">
                <div className="flex gap-10">
                  <div className={styles.quizcontainer}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <SelectGroup
                            label="Select Class"
                            required
                            children={
                              <select
                                name="class_id"
                                onChange={handleAssignmentChange}
                              >
                                <option value="">--- Select ---</option>
                                {classData?.map((clss) => {
                                  return (
                                    <option key={clss?.id} value={clss?.id}>
                                      {clss?.class_name}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          />
                          {assignmentValidator.message(
                            "class_id",
                            assignmentForm?.class_id,
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <SelectGroup
                            label="Select Subject"
                            required
                            children={
                              <select
                                name="subject_id"
                                onChange={handleAssignmentChange}
                              >
                                <option value="">--- Select ---</option>

                                {subjectData?.map((sub) => {
                                  return (
                                    <option key={sub?.id} value={sub?.id}>
                                      {sub?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          />
                          {assignmentValidator.message(
                            "subject_id",
                            assignmentForm?.subject_id,
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Assignment Title"
                            margin="normal"
                            inputType="text"
                            inputName="title"
                            onChange={handleAssignmentChange}
                            required
                          />
                          {assignmentValidator.message(
                            "title",
                            assignmentForm?.title,
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Submission Date"
                            margin="normal"
                            inputType="date"
                            inputName="submission_date"
                            onChange={handleAssignmentChange}
                            required
                          />
                          {assignmentValidator.message(
                            "submission_date",
                            assignmentForm?.submission_date,
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <InputGroup
                            label="Assignment Instruction"
                            margin="normal"
                            inputType="text"
                            inputName="instruction"
                            onChange={handleAssignmentChange}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <div className={styles.inputmargin}>
                            <TextAreaGroup
                              label="Description"
                              inputName="description"
                              margin="normal"
                              placeholder=""
                              onChange={handleAssignmentChange}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
              <div className="margin-top">
                {questionArr?.map((que, queInd) => {
                  return (
                    <div key={queInd} className={styles.questioncontainer}>
                      <Grid container spacing={2}>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                          <>
                            <InputGroup
                              label={`Enter Question ${que?.question_index}`}
                              margin="normal"
                              required
                              inputType="text"
                              inputName="question"
                              onChange={(e) => {
                                handleQueChange(e, queInd);
                              }}
                            />
                            {assignmentValidator.message(
                              "question",
                              questionArr[queInd]["question"],
                              "required"
                            )}
                            {que?.type === "paragraph" ? (
                              <div className={styles.inputmargin}>
                                <TextAreaGroup
                                  margin="normal"
                                  placeholder=""
                                  disabled
                                />
                              </div>
                            ) : null}
                            {que?.type === "date" ? (
                              <InputGroup
                                label=""
                                margin="normal"
                                placeHolder="Enter Value here"
                                inputType="date"
                                disabled
                              />
                            ) : null}
                            {que?.type === "time" ? (
                              <InputGroup
                                label=""
                                margin="normal"
                                inputType="time"
                                disabled
                              />
                            ) : null}

                            <></>

                            {"description" in que ? (
                              <div className="flex gap-10 align-items-center margin-top full-width">
                                <div className="half-width">
                                  <InputGroup
                                    label="Question Description"
                                    margin="normal"
                                    placeHolder="Enter Value here"
                                    inputType="text"
                                    inputName="description"
                                    onChange={(e) => handleQueChange(e, queInd)}
                                  />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() => removeQueDescription(queInd)}
                                >
                                  <Icon
                                    icon="ooui:close"
                                    width="20"
                                    height="20"
                                  />
                                </div>
                              </div>
                            ) : null}
                          </>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <SelectGroup
                              label="Choose Question Type"
                              required
                              children={
                                <select
                                  name="type"
                                  onChange={(e) => handleQueChange(e, queInd)}
                                >
                                  <option value="">--- Select ---</option>
                                  {options?.map((label) => {
                                    return (
                                      <option
                                        key={label?.key}
                                        value={label?.value}
                                      >
                                        <Icon
                                          className={`${styles.add_question_color}`}
                                          icon="flat-color-icons:plus"
                                          width="24"
                                          height="24"
                                        />
                                        {label?.text}
                                      </option>
                                    );
                                  })}
                                </select>
                              }
                            />
                            {assignmentValidator.message(
                              "question type",
                              questionArr[queInd]["type"],
                              "required"
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* Multiple Choice */}
                      {que?.type === "multiple_choice" ? (
                        <>
                          <Grid container spacing={2} mt={1}>
                            {que?.options?.map((radio, i) => {
                              return (
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  key={i}
                                >
                                  <div>
                                    <InputGroup
                                      label=""
                                      margin="normal"
                                      placeHolder="Enter Value here"
                                      inputType="text"
                                      onChange={(e) => handleMultipleChoiceChange(e, i, queInd)
                                      }
                                    />
                                    {assignmentValidator.message(
                                      "option choice",
                                      questionArr[queInd]["options"][i],
                                      "required"
                                    )}
                                    <div
                                      className="flex gap-10 align-items-center justify-items-space"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <div className={styles.correctcontainer}>
                                        <input
                                          type="radio"
                                          name="answer"
                                          value={radio}
                                          onChange={(e) => handleCorrectChoiceChange(
                                            e,
                                            i,
                                            queInd
                                          )
                                          }
                                        />
                                        <label
                                          className={styles.correctlabel}
                                          htmlFor="Is Correct"
                                        >
                                          is correct
                                        </label>
                                      </div>
                                      {/* if radio option is more thatn 1, show close*/}
                                      {que?.options?.length > 1 ? (
                                        <div
                                          onClick={() => {
                                            removeAdditionalChoice(i, queInd);
                                          }}
                                          className="delete-icon flex gap-10 align-items"
                                        >
                                          {" "}
                                          Remove Option
                                          <Icon
                                            icon="ooui:close"
                                            width="20"
                                            height="20"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                          <div
                            className="additional-input flex align-items-center"
                            onClick={() => handleAdditionalOptions(queInd)}
                          >
                            <Icon
                              icon="octicon:feed-plus-16"
                              width="16"
                              height="16"
                            />
                            <p>Add another option</p>
                          </div>
                        </>
                      ) : null}

                      {/* Select Dropdown */}
                      {que?.type === "dropdown" ? (
                        <>
                          <Grid container spacing={2} mt={1}>
                            {que?.options?.map((dropdown, i) => {
                              return (
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  key={i}
                                >
                                  <div>
                                    <InputGroup
                                      label=""
                                      margin="normal"
                                      placeHolder="Enter Option here"
                                      inputType="text"
                                      onChange={(e) => handleMultipleChoiceChange(e, i, queInd)
                                      }
                                    />
                                    {assignmentValidator.message(
                                      "option choice",
                                      questionArr[queInd]["options"][i],
                                      "required"
                                    )}

                                    <div
                                      className="flex gap-10 align-items-center justify-items-space"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <div className={styles.correctcontainer}>
                                        <input
                                          type="radio"
                                          name="answer"
                                          value={dropdown}
                                          onChange={(e) => handleCorrectChoiceChange(
                                            e,
                                            i,
                                            queInd
                                          )
                                          }
                                        />
                                        <label
                                          className={styles.correctlabel}
                                          htmlFor="Is Correct"
                                        >
                                          is correct
                                        </label>
                                      </div>
                                      {/* if select drop is more thatn 1, show close*/}
                                      {que?.options?.length > 1 ? (
                                        <div
                                          onClick={() => {
                                            removeAdditionalChoice(i, queInd);
                                          }}
                                          className="delete-icon flex gap-10 align-items"
                                        >
                                          Remove Option
                                          <Icon
                                            icon="ooui:close"
                                            width="20"
                                            height="20"
                                            className="delete-icon"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                          <div
                            className="additional-input flex align-items-center"
                            onClick={() => handleAdditionalOptions(queInd)}
                          >
                            <Icon
                              icon="octicon:feed-plus-16"
                              width="16"
                              height="16"
                            />
                            <p>Add another option</p>
                          </div>
                        </>
                      ) : null}

                      {/* Checkbox */}
                      {que?.type === "checkbox" ? (
                        <>
                          <Grid container spacing={2} mt={1}>
                            {que?.options?.map((checkbox, i) => {
                              return (
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                  key={i}
                                >
                                  <div>
                                    <InputGroup
                                      label=""
                                      margin="normal"
                                      placeHolder="Enter Value here"
                                      inputType="text"
                                      onChange={(e) => handleMultipleChoiceChange(e, i, queInd)
                                      }
                                    />
                                    {assignmentValidator.message(
                                      "option choice",
                                      questionArr[queInd]["options"][i],
                                      "required"
                                    )}
                                    <div
                                      className="flex gap-10 align-items-center justify-items-space"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <div className={styles.correctcontainer}>
                                        <input
                                          type="checkbox"
                                          name="answer"
                                          value={checkbox}
                                          onChange={(e) => handleCorrectChoiceChange(
                                            e,
                                            i,
                                            queInd
                                          )
                                          }
                                        />
                                        <label
                                          className={styles.correctlabel}
                                          htmlFor="Is Correct"
                                        >
                                          is correct
                                        </label>
                                      </div>
                                      {/* if checkbox option is more thatn 1, show close*/}
                                      {que?.options?.length > 1 ? (
                                        <div
                                          onClick={() => {
                                            removeAdditionalChoice(i, queInd);
                                          }}
                                          className="delete-icon flex gap-10 align-items"
                                        >
                                          {" "}
                                          Remove Option
                                          <Icon
                                            icon="ooui:close"
                                            width="20"
                                            height="20"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                          <div
                            className="additional-input flex align-items-center"
                            onClick={() => handleAdditionalOptions(queInd)}
                          >
                            <Icon
                              icon="octicon:feed-plus-16"
                              width="16"
                              height="16"
                            />
                            <p>Add another checkbox</p>
                          </div>
                        </>
                      ) : null}

                      <div className={styles.divider}>
                        <hr />
                      </div>
                      <div className={styles.questionfooter}>
                        <div>
                          <input
                            className={styles.pointsInput}
                            type="number"
                            min={1}
                            name="grade_point"
                            placeholder="Enter Question Points"
                            onChange={(e) => {
                              handleQueChange(e, queInd);
                            }}
                          />
                          {assignmentValidator.message(
                            "points",
                            questionArr[queInd]["grade_point"],
                            "required"
                          )}
                        </div>
                        <div className="flex gap-10 align-items-center justifyRight">
                          {questionArr?.length > 1 ? (
                            <>
                              <Icon
                                icon="material-symbols:delete-outline"
                                width="20"
                                height="20"
                                className="delete-icon"
                                onClick={() => removeAdditionalQue(queInd)}
                              />
                              <Icon
                                icon="ph:line-vertical"
                                width="27"
                                height="27"
                              />
                            </>
                          ) : null}
                          <FormControlLabel
                            label="Required"
                            name="required"
                            required
                            control={<Switch />}
                            onChange={(e) => handleReqQueChange(e, queInd)}
                          />
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : null}
                            aria-expanded={open ? "true" : null}
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, queInd)}
                          >
                            <Icon
                              icon="radix-icons:dots-vertical"
                              width="20"
                              height="20"
                            />
                          </IconButton>
                        </div>
                      </div>

                      <Menu
                        id="basic-menu"
                        MenuListProps={{
                          "aria-labelledby": "basic-button"
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleAdditionalQueClose}
                      >
                        <MenuItem
                          onClick={() => {
                            toggleQueDescriptionTrue();
                          }}
                        >
                          Add Description on Question
                        </MenuItem>
                      </Menu>
                    </div>
                  );
                })}
                <div
                  className="additional-input flex align-items-center"
                  onClick={handleAdditionalQuestion}
                >
                  <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                  <p>Add another question</p>
                </div>
                <Grid>
                  <div className={styles.wrapper}>
                    <Button
                      variant="primary"
                      color="btndefault"
                      buttonSize="full"
                      buttonName="Create Assignment"
                      onClick={handleCreateAssignment}
                      isLoading={isCreating}
                    />
                  </div>
                </Grid>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      }
    />
  );
};

export default CreateAssignment;
