import { useState } from "react";
import Layout from "../../components/Layout";
import useStyles from "./styles";
import { Grid } from "@mui/material";
import Button from "../../components/Button";
import CardCount from "../../components/CardCount";
import { Group as StudentIcon, MenuBook as Book, Folder, Class as ClassIcon, AddCircle } from "@mui/icons-material";
import PageTitle from "../../components/PageTitle";
import InputGroup from "../../components/FormComponent/InputGroup";
import Modal from "../../components/Modal/index";
import WidgetTitle from "./components/WidgetTitle/WidgetTitle";
import { useNavigate } from "react-router-dom";
// import Table from "../../components/Table/Table";
import EmployeeOverviewData from "../../dummy_data/admindashboardData.json";
import useWindowSize from "../../hooks/useWindow";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { toast } from "react-toastify";
import { httprequest } from "../../data/api";
import NoticeBoardCard from "./components/NoticeBoardCard/NoticeBoardCard";
import AdminLeaveCard from "./components/AdminLeaveCard/AdminLeaveCard";
import DetailsText from "../leavemgt/components/DetailsText";
import ClassPeriod from "./components/ClassPeriodCard";

const LEAVE_MGT_ENDPOINT = "/api/v1/leave";

const EmployeeDashboard = () => {
  const classes = useStyles();
  const [modalisopen, setModalIsOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [currentLeave, setCurrentLeave] = useState({});
  const navigate = useNavigate();

  const width = useWindowSize();
  const mobile = width < 650;

  const handleCloseApproveModal = () => {
    setApproveModal(false);
  };

  // Set Start and End Date
  // const startDate = new Date(activeTerm?.start_date);
  // const EndDate = new Date(activeTerm?.end_date);

  // const generateEvents = () => {
  //   const allEvents = [];
  //   const currentDate = new Date(startDate);

  //   while (currentDate <= EndDate) {
  //     const weekDay = currentDate
  //     // Get day and convert to lowercase abbreviat
  //       .toLocaleDateString("en-US", { weekday: "short" })
  //       .toLowerCase();

  //     periodData?.data?.days.forEach((day) => {
  //       if (weekDay === day?.day) {
  //         day?.periods.forEach((periodItem) => {
  //           const event = {
  //             type: periodItem?.type,
  //             title: periodItem?.subject?.name || periodItem?.title,
  //             start: `${currentDate.toISOString().split("T")[0]}T${periodItem?.start_time}`,
  //             end: `${currentDate.toISOString().split("T")[0]}T${periodItem?.end_time}`,
  //             teacher: periodItem?.teachers?.[0]?.name,
  //             subject_name: periodItem?.subject?.name,
  //             subject_id: periodItem?.subject?.id,
  //             day: day?.day,
  //             class: periodData?.data?.class?.name,
  //             class_id: periodData?.data?.class?.id,
  //             term: periodData?.data?.term?.name,
  //             term_id: periodData?.data?.term?.id,
  //             period_id: periodItem?.id,
  //             className: "general-events"
  //           };
  //           allEvents.push(event);
  //         });
  //       }
  //     });

  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }

  //   return allEvents;
  // };

  // const studentData = [];

  // const studentColumns = [
  //   {
  //     name: "Name",
  //     selector: row => row?.name
  //   },
  //   {
  //     name: "Class",
  //     selector: row => row?.role
  //   },
  //   {
  //     name: "Gender",
  //     selector: row => row?.role
  //   },
  //   {
  //     name: "Parent",
  //     selector: row => row?.role
  //   },
  //   {
  //     name: "Contact Info",
  //     selector: row => row?.role
  //   }
  // ];

  const periods = [
    { start_time: "06:00:00", end_time: "07:40:00", period_subject: "Math", period_class: "JSS 1" },
    { start_time: "09:00:00", end_time: "08:00:00", period_subject: "Science", period_class: "JSS 3" },
    { start_time: "10:00:00", end_time: "12:30:00", period_subject: "English", period_class: "SSS 3" },
    { start_time: "13:00:00", end_time: "13:30:00", period_subject: "English", period_class: "JSS 2" },
    { start_time: "14:00:00", end_time: "16:30:00", period_subject: "English", period_class: "SSS 1" },
    { start_time: "13:00:00", end_time: "13:30:00", period_subject: "English", period_class: "SSS 3" }
  ];

  const handleApproveLeave = (leave_id) => {
    setIsApproving(true);
    httprequest
      .patch(`${LEAVE_MGT_ENDPOINT}/status/${leave_id}`, { leave_status: "approved" })
      .then((res) => {
        setIsApproving(false);
        handleCloseApproveModal();
        // handleGetOverview();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        setIsApproving(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDeclineLeave = (leave_id) => {
    setIsDeclining(true);
    httprequest
      .patch(`${LEAVE_MGT_ENDPOINT}/status/${leave_id}`, { leave_status: "declined" })
      .then(() => {
        setIsDeclining(false);
        handleCloseApproveModal();
        // handleGetOverview();
        toast.success("Leave status has been declined");
      })
      .catch((err) => {
        setIsDeclining(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Dashboard"
            subtitle="Keep up with everything going on"
            button={
              <Button
                variant="primary"
                buttonSize="small"
                color="btndefault"
                onClick={() => {
                  setModalIsOpen(true);
                }}
                buttonName="Schedule Class"
              />
            }
          />
          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Students"
                count={1450}
                icon={<StudentIcon />}
                active={EmployeeOverviewData?.classes?.[0]?.active_classes || 0}
                inactive={EmployeeOverviewData?.classes?.[0]?.inactive_classes || 0}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Subjects"
                count={1450}
                icon={<Book />}
                active={EmployeeOverviewData?.classes?.[0]?.active_classes || 0}
                inactive={EmployeeOverviewData?.classes?.[0]?.inactive_classes || 0}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Classes"
                count={14}
                icon={<ClassIcon />}
                active={EmployeeOverviewData?.classes?.[0]?.active_classes || 0}
                inactive={EmployeeOverviewData?.classes?.[0]?.inactive_classes || 0}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Lesson Note"
                count={50}
                icon={<Folder />}
                active={EmployeeOverviewData?.classes?.[0]?.active_classes || 0}
                inactive={EmployeeOverviewData?.classes?.[0]?.inactive_classes || 0}
              />
            </Grid>
          </Grid>
          {/* <div className="cover-page">
            <WidgetTitle
              title="My TimeTable Schedule"
              subtitle=""
              // link={<Link className={classes.widgetlink} to="/teachers"><CalendarToday /> See full schedule</Link>}
            />

            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label="class tabs"
                >
                  {allClassesData?.map((data) => {
                    return (
                      <Tab
                        key={data?.class?.id}
                        value={data?.class?.id}
                        label={data?.class?.name}
                      />
                    );
                  })}
                </Tabs>
              </div>
              {allClassesData?.map((data) => {
                return (
                  <TabPanel key={data?.class?.id} value={data?.class?.id}>
                    <div className={classes.calendar}>
                      <Calendar events={events} renderEventContent={renderEventContent} defaultView={renderView()} />
                    </div>
                  </TabPanel>
                );
              })}
            </TabContext>
          </div> */}
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Today's Classes"
                subtitle="View your schedule for the day"
              />
              <div className={classes.period}>
                <div className={classes.static}>
                  <h3 onClick={() => navigate("/class-activities/classes/class-calendar")}>View all</h3>
                  <hr />
                </div>
                <div className={classes.periodclasses}>
                  <div className={classes.preriodflex}>
                    {periods?.map((period, index) => (
                      <div key={index} className={classes.perioditem}>
                        <ClassPeriod
                          start_time={period?.start_time}
                          end_time={period?.end_time}
                          period_subject={period?.period_subject}
                          period_class={period?.period_class}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <WidgetTitle
            title="Events"
            subtitle="See all the events in your organisation"
          />
          <div className={classes.events}>
            <h3 onClick={() => navigate("/events")}>View all</h3>
            <hr />
            <div className={classes.chrono}>
              <Stepper activeStep={1} alternativeLabel={!mobile} orientation={mobile ? "vertical" : "horizontal"}>
                {EmployeeOverviewData?.events.map((label) => (
                  <Step key={label.id}>
                    <StepLabel>
                      <div>
                        <span>{label?.title}</span>
                        <br />
                        <span>{label?.date}</span>
                        <br />
                        <span>{label?.description}</span>
                      </div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>

          <Grid container spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Notice Board"
                subtitle="View important information for your school"
              />
              <div className={classes.notice}>
                <div className={classes.static}>
                  <h3 onClick={() => navigate("")}>View all</h3>
                  <hr />
                </div>
                <div className={classes.pendingleaves}>
                  {EmployeeOverviewData?.notice_board?.map((notice) => (
                    <div key={notice.id}>
                      <NoticeBoardCard
                        title={notice.title}
                        date={notice.created_at}
                        teacher={notice.added_by?.name}
                      />
                      <hr />
                    </div>
                  ))}

                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Student Leave Requests"
                subtitle="View leave request of student in your class"
              />
              <div className={classes.leave}>
                <div className={classes.static}>
                  <h3 onClick={() => navigate("/manage/leave")}>View all</h3>
                  <hr />
                </div>
                <div className={classes.pendingleaves}>
                  {EmployeeOverviewData?.pending_leaves?.map((leaves) => (
                    <AdminLeaveCard
                      key={leaves?.id}
                      to={`/manage/leave/${leaves?.id}`}
                      t_name={leaves?.leave_requester?.name}
                      t_role={leaves?.role?.name}
                      start_date={leaves?.start_date}
                      end_date={leaves?.end_date}
                      approveClick={() => {
                        setCurrentLeave(leaves);
                        setActionType("approve");
                        setApproveModal(true);
                      }}
                      declineClick={() => {
                        setCurrentLeave(leaves);
                        setActionType("decline");
                        setApproveModal(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <WidgetTitle
            title="Students"
            subtitle="See all the students in your class"
            link={<Link className={classes.widgetlink} to="/teachers">View all students</Link>}
          />
          <Table data={studentData} columns={studentColumns} /> */}

          <Modal
            title="Add a class"
            subtitle="You can add a new class and assign a teacher to the class."
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Class Name"
                      margin="normal"
                      placeHolder="Enter Class Name"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Class Teacher"
                      margin="normal"
                      placeHolder="Select Class Teacher"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Subjects"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Subjects"
                      inputType="text"
                    />
                  </Grid>
                </Grid>
                <div className={`${classes.extrasubject}`}>
                  <p className={classes.subject_add}>Add another Subject</p>
                  <AddCircle className={classes.addicon} />
                </div>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Add Class" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={modalisopen}
            closeModal={() => {
              setModalIsOpen(false);
            }}
          />

          {/* Approve Leave */}
          <Modal
            title="Handle Leave Request"
            subtitle={`Approve Leave of ${currentLeave?.leave_requester?.name}`}
            modalContent={
              <>
                <div className="margin-top-30">
                  <Grid container spacing={1}>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Role" subtitle={currentLeave?.role?.name} />
                      {/* <h3>Role: {currentLeave?.role?.name}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Start Date:" subtitle={currentLeave?.start_date} />
                      {/* <h3>Start Date: {currentLeave?.start_date}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="End Date:" subtitle={currentLeave?.end_date} />
                      {/* <h3>End Date: {currentLeave?.end_date}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Number of Days" subtitle={currentLeave?.number_of_days} />
                      {/* <h3>Number of Days: {currentLeave?.number_of_days}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Leave Type:" subtitle={currentLeave?.leave_type?.name} />
                      {/* <h3>Leave Type: {currentLeave?.leave_type?.name}</h3> */}
                    </Grid>

                  </Grid>
                </div>
              </>
            }
            modalFooter={
              <>
                {actionType === "approve" ? (
                  <Button
                    variant="success"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Approve Leave"
                    onClick={() => handleApproveLeave(currentLeave?.id)}
                    isLoading={isApproving}
                  />
                ) : (
                  <Button
                    variant="danger"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Decline Leave"
                    onClick={() => handleDeclineLeave(currentLeave?.id)}
                    isLoading={isDeclining}
                  />
                )}
              </>
            }
            isopen={approveModal}
            closeModal={() => setApproveModal(false)}
          />

        </>
      }
    />
  );
};

export default EmployeeDashboard;
