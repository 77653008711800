/* eslint-disable prefer-template */
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import Table from "../../../components/Table/Table";
import { useState, useEffect } from "react";
import useStyles from "../styles";
import { Grid } from "@mui/material";
import Modal from "../../../components/Modal";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { TableSearch } from "../../../components/Table/TableActions";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { useNavigate } from "react-router-dom";
// import ButtonCount from "../../../components/ButtonCount";
import { httprequest } from "../../../data/api";
import useValidator from "../../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";
import Badge from "../../../components/Badge";
import { Icon } from "@iconify/react";
import Select from "react-select";

const TERM_ENDPOINT = "/api/v1/school-plan/term";
const SESSION_ENDPOINT = "/api/v1/school-plan/session";
const SCHOOL_PLAN_ENDPOINT = "/api/v1/school-plan";
const ROLES_ENDPOINT = "/api/v1/roles";

const SchoolPlans = () => {
  const navigate = useNavigate();
  const [planValidator, showPlanValidator] = useValidator();
  const [editPlanValidator, showEditPlanValidator] = useValidator();
  const [searchResult, setSearchResult] = useState([]);
  const [selectedSessionTerms, setSelectedSessionTerms] = useState([]);
  const classes = useStyles();
  const [schoolplanModal, setSchoolPlanModal] = useState(false);
  const [editPlanModal, setEditPlanModal] = useState(false);
  const [deletePlanModal, setDeletePlanModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  // const [termData, setTermsData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [plansData, setPlansData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isPlansFetching, setIsPlansFetching] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [schoolPlan, setSchoolPlan] = useState({
    title: "",
    term: "",
    session: "",
    start_date: "",
    end_date: "",
    access: ""
  });

  const [editPlan, setEditPlan] = useState({
    title: "",
    term: "",
    session: "",
    start_date: "",
    end_date: "",
    description: "",
    access: ""
  });

  const getRoles = () => {
    httprequest.get(ROLES_ENDPOINT).then((res) => {
      const access = res?.data?.data?.filter((res) => res?.role_type !== "school_admin");
      setRolesData(access?.map((role) => {
        return {
          label: role?.role_name,
          value: role?.role_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleSchoolPlanModal = () => {
    setSchoolPlanModal(true);
  };

  const handleCloseSchoolPlanModal = () => {
    setSchoolPlanModal(false);
  };

  const handleMultipleAccessChange = (event) => {
    const selectedValue = event.map((item) => item.value);
    setSchoolPlan({ ...schoolPlan, access: selectedValue });
  };

  const handleEditMultipleAccessChange = (event) => {
    const selectedAccess = event.map((option) => ({
      id: option?.id,
      name: option?.name
    }));

    setEditPlan((prevEditPlan) => ({
      ...prevEditPlan,
      access: selectedAccess
    }));
  };

  const handleCloseEditPlanModal = () => {
    setCurrentRow({});
    setEditPlan({
      ...editPlan,
      title: "",
      term: "",
      session: "",
      start_date: "",
      end_date: "",
      description: ""
    });
    setEditPlanModal(false);
  };

  const getAllTerms = (Session_ID) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        // setTermsData(res?.data?.data);
        setSelectedSessionTerms(res?.data?.data.filter((selected_term) => selected_term?.session_id === Session_ID));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (editPlanModal && editPlan?.session?.id) {
      getAllTerms(editPlan?.session?.id);
    }
  }, [editPlanModal, editPlan?.session?.id]);

  const handleEditPlanModal = (row) => {
    setCurrentRow(row);
    setEditPlan({
      ...editPlan,
      access: row?.access,
      title: row?.title,
      term: row?.term?.id,
      session: row?.session?.id,
      start_date: row?.start_date,
      end_date: row?.end_date,
      description: row?.description
    });
    setEditPlanModal(true);
  };
  // eslint-disable-next-line no-console
  console.log(editPlan?.term, "TERM");

  // Handle Session Change
  const handleSessionChange = (e) => {
    const { name, value } = e.target;
    setSchoolPlan({ ...schoolPlan, [name]: value });
    getAllTerms(value);
  };

  // Handle Session Change
  const handleEditSessionChange = (e) => {
    const { name, value } = e.target;
    setEditPlan({ ...editPlan, [name]: value });
    getAllTerms(value);
  };

  useEffect(() => {
    if (editPlanModal && editPlan?.session?.id) {
      getAllTerms(editPlan?.session?.id);
    }
  }, [editPlanModal, editPlan?.session?.id]);

  const handleDeleteModal = (row) => {
    setDeletePlanModal(true);
    setCurrentRow(row);
  };

  const handleCloseDeleteModal = () => {
    setDeletePlanModal(false);
    setCurrentRow({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSchoolPlan({ ...schoolPlan, [name]: value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditPlan({ ...editPlan, [name]: value });
  };

  const handleEditTermChange = (e) => {
    const { name, value } = e.target;
    setEditPlan({ ...editPlan, [name]: value });
  };

  const getAllSession = () => {
    httprequest
      .get(SESSION_ENDPOINT)
      .then((res) => {
        setSessionData(res?.data?.data);
      })
      .catch(() => {});
  };

  const getAllPlans = () => {
    setIsPlansFetching(true);
    httprequest
      .get(SCHOOL_PLAN_ENDPOINT)
      .then((res) => {
        setIsPlansFetching(false);
        setPlansData(res?.data?.data);
        setSearchResult(res?.data?.data);
      })
      .catch((err) => {
        setIsPlansFetching(false);
        toast.error(err?.response?.data);
      });
  };

  // eslint-disable-next-line no-console
  console.log(plansData, "plansData");

  const handleCreatePlan = () => {
    if (planValidator.allValid()) {
      setIsLoading(true);
      httprequest
        .post(SCHOOL_PLAN_ENDPOINT, schoolPlan)
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.data?.message);
          getAllPlans();
          setSchoolPlan({
            ...schoolPlan,
            term: "",
            session: "",
            start_date: "",
            end_date: "",
            description: "",
            access: ""
          });
          handleCloseSchoolPlanModal();
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showPlanValidator(true);
    }
  };

  const handleDeletePlan = () => {
    setIsDeleteLoading(true);
    httprequest
      .delete(`${SCHOOL_PLAN_ENDPOINT}/${currentRow?.id}`)
      .then((res) => {
        setIsDeleteLoading(false);
        toast.success(res?.data?.message);
        getAllPlans();
        handleCloseDeleteModal();
      })
      .catch((err) => {
        setIsDeleteLoading(false);
        toast.error(err?.response?.data);
      });
  };

  // --| Edit Plan API Call
  const handleEditPlan = () => {
    if (editPlanValidator.allValid()) {
      setIsEditLoading(true);
      httprequest
        .patch(`${SCHOOL_PLAN_ENDPOINT}/${currentRow?.id}`, editPlan)
        .then((res) => {
          setIsEditLoading(false);
          toast.success(res?.data?.message);
          getAllPlans();
          setEditPlan({
            ...schoolPlan,
            term: "",
            session: "",
            start_date: "",
            end_date: "",
            description: "",
            access: ""
          });
          handleCloseEditPlanModal();
        })
        .catch((err) => {
          setIsEditLoading(false);
          toast.error(err?.response?.data);
        });
    } else {
      showEditPlanValidator(true);
    }
  };

  useEffect(() => {
    getAllSession();
    getAllTerms();
    getAllPlans();
    getRoles();
  }, []);

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title || "--------------"
    },
    {
      name: "Access",
      selector: (row) => row?.access?.map((acc) => acc?.name)
    },
    {
      name: "Description",
      selector: (row) => row?.description || "--------------"
    },
    {
      name: "Created At",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Status",
      center: true,
      cell: (row) => <Badge status={row?.status} />
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            // onClick={() => {
            //   handleOpenInventoryModal(row);
            // }}
            className="table-action-icon"
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            className="table-action-icon"
            onClick={() => handleEditPlanModal(row)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];
  // --| Filter School Plan table using name, email and class
  const handleSearchPlan = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = plansData?.filter((data) => valArray?.every(
      (word) => data?.title?.toLowerCase().includes(word.toLowerCase()) ||
          data?.description?.toLowerCase().includes(word.toLowerCase()) ||
          data?.access?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const { title, session, term, access, description, end_date, start_date } =
    schoolPlan;

  return (
    <>
      <PageTitle
        title="School Plans"
        subtitle="See all the plans that has been set for the school period."
        button={
          <div className="flex gap-10">
            <Button
              onClick={() => {
                navigate("/schoolplans/view-school-plans/school-calender");
              }}
              variant="primaryoutline"
              buttonSize="fluid"
              color="btnfontprimary"
              buttonName="View School Calendar"
            />
            <Button
              variant="primary"
              onClick={handleSchoolPlanModal}
              buttonSize="fluid"
              color="btndefault"
              buttonName="Create a School Plan"
            />
          </div>
        }
      />
      <div className={classes.pageContent}>
        {/* <div className="button-count-container">
          <Grid container spacing={2} className="input-padding">
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ButtonCount
                title="View Created Sessions"
                func={() => navigate("/schoolsession")}
                count={sessionData?.length}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <ButtonCount
                title="View Created Terms"
                func={() => navigate("/schoolterm")}
                count={termData?.length}
              />
            </Grid>
          </Grid>
        </div> */}
        <Table
          data={searchResult}
          columns={columns}
          subHeader={true}
          pagination
          subHeaderComponent={
            <TableSearch
              placeholder="Search here..."
              searchTableFunc={handleSearchPlan}
              isLoading={isPlansFetching}
            />
          }
        />

        {/* Create School Plan */}
        <Modal
          title="Create a School Plan"
          subtitle={<div>Create a new school plan for your school.</div>}
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Title"
                    margin="normal"
                    placeHolder="Enter title"
                    inputType="text"
                    onChange={handleChange}
                    inputName="title"
                    value={title}
                    required
                  />
                  {planValidator.message("Title", title, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Select Session"
                    required
                    children={
                      <select
                        onChange={handleSessionChange}
                        name="session"
                        value={session}
                      >
                        <option value="">---Select---</option>
                        {sessionData?.map((sesh) => {
                          return (
                            <option key={sesh?.id} value={sesh?.id}>
                              {sesh?.session}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {planValidator.message("Session", session, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="multiple-select">
                    <p>Access</p>
                    <Select isMulti name="access"
                      closeMenuOnSelect={true} options={rolesData} onChange={handleMultipleAccessChange}
                    />
                  </div>
                  {planValidator.message("Access", access, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Select Term"
                    required
                    children={
                      <select onChange={handleChange} name="term" value={term}>
                        <option value="">---Select---</option>
                        {selectedSessionTerms?.map((data) => {
                          return (
                            <option key={data?.id} value={data?.id}>
                              {data?.term}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {planValidator.message("Term", term, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    required
                    label="Start Date"
                    margin="normal"
                    inputType="date"
                    onChange={handleChange}
                    inputName="start_date"
                    value={start_date}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    required
                    label="End Date"
                    margin="normal"
                    inputType="date"
                    onChange={handleChange}
                    inputName="end_date"
                    value={end_date}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    required
                    label="Description"
                    margin="normal"
                    inputType="text"
                    onChange={handleChange}
                    inputName="description"
                    value={description}
                  />
                </Grid>
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  buttonName="Create School Plan"
                  onClick={handleCreatePlan}
                  isLoading={isLoading}
                />
              </div>
            </>
          }
          isopen={schoolplanModal}
          closeModal={handleCloseSchoolPlanModal}
        />

        {/* Edit School Plan */}
        <Modal
          title="Edit a School Plan"
          subtitle={<div>Edit the current school plan for your school.</div>}
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Title"
                    margin="normal"
                    required
                    placeHolder="Enter title"
                    inputType="text"
                    onChange={handleEditChange}
                    inputName="title"
                    defaultValue={editPlan?.title}
                  />
                  {editPlanValidator.message("Title", editPlan?.title, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Select Session"
                    required
                    children={
                      <select
                        onChange={handleEditSessionChange}
                        name="session"
                        defaultValue={editPlan?.session}
                      >
                        <option value="">---Select---</option>
                        {sessionData?.map((sesh) => {
                          return (
                            <option key={sesh?.id} value={sesh?.id}>
                              {sesh?.session}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {editPlanValidator.message("Session", editPlan?.session, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="multiple-select">
                    <p>Access</p>
                    <Select isMulti name="access"
                      closeMenuOnSelect={true} options={rolesData}
                      onChange={handleEditMultipleAccessChange}
                      defaultValue={editPlan?.access}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                    />
                  </div>
                  {planValidator.message("Access", access, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Select Term"
                    required
                    children={
                      <select
                        onChange={handleEditTermChange}
                        name="term"
                        defaultValue={editPlan?.term}
                      >
                        <option value="">---Select---</option>
                        {selectedSessionTerms?.map((data) => {
                          return (
                            <option key={data?.id} value={data?.id}>
                              {data?.term}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {editPlanValidator.message("Term", editPlan?.term, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Start Date"
                    margin="normal"
                    inputType="date"
                    required
                    onChange={handleEditChange}
                    inputName="start_date"
                    defaultValue={editPlan?.start_date?.substring(0, 10)}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="End Date"
                    margin="normal"
                    inputType="date"
                    required
                    onChange={handleEditChange}
                    inputName="end_date"
                    defaultValue={editPlan?.end_date?.substring(0, 10)}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Description"
                    margin="normal"
                    inputType="text"
                    required
                    onChange={handleEditChange}
                    inputName="description"
                    value={editPlan?.description}
                  />
                </Grid>
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  buttonName="Edit School Plan"
                  onClick={handleEditPlan}
                  isLoading={isEditLoading}
                />
              </div>
            </>
          }
          isopen={editPlanModal}
          closeModal={handleCloseEditPlanModal}
        />

        {/* Delete Modal */}

        <Modal
          title="Delete Current School Plan"
          subtitle={`Are you sure you want to delete this  ${currentRow?.title} ?. If you delete it, it becomes unavaiable for the whole school.`}
          modalContent={
            <>
              <div></div>
            </>
          }
          modalFooter={
            <>
              <Button
                type="submit"
                variant="danger"
                buttonName="Delete Selected School Plan"
                buttonSize="full"
                color="btndefault"
                onClick={handleDeletePlan}
                isLoading={isDeleteLoading}
              />
            </>
          }
          isopen={deletePlanModal}
          closeModal={handleCloseDeleteModal}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default SchoolPlans;
