/* eslint-disable no-console */
/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
// import PageTitle from "../../components/PageTitle/PageTitle";
import BreadCrumb from "../../components/BreadCrumb";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import { Icon } from "@iconify/react";
import Button from "../../components/Button";
import Select from "react-select";
import Modal from "../../components/Modal";
import Badge from "../../components/Badge";
import { httprequest } from "../../data/api";
import styles from "./studentApplication.module.css";
import InputGroup from "../../components/FormComponent/InputGroup";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import EnrolBanner from "../../assets/images/enrolbanner.jpg";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import TextAreaGroup from "../../components/FormComponent/TextAreaGroup";
import { getDocument } from "pdfjs-dist/build/pdf";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import nocomment from "../../assets/images/nocomment.png";

const EnrolmentDetails = () => {
  const classes = useStyles();
  const [currentRow, setCurrentRow] = useState({});
  const [addinformationModal, setInformationModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [enrolmentInfoData, setEnrolmentInfoData] = useState([]);
  const [applicantsData, setApplicantsData] = useState([]);
  const [applicantsFilter, setApplicantsFilter] = useState([]);
  const { id } = useParams();
  const [selectedAction, setSelectedAction] = useState("");
  const [actionModal, setActionModal] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [convertModal, setConvertModal] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [messageModal, setMessageModal] = useState("");
  const [requirePayment, setRequirePayment] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [comments, setComments] = useState([]);
  const [replyComment, setReplyComment] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState(null);
  const [rejectComment, setRejectComment] = useState("");
  const [formData, setFormData] = useState({
    venue: "",
    examDate: "",
    examTime: ""
  });
  const ENROLMENT_ENDPOINT = "/api/v1/enrolment";
  const COMMENTS_ENDPOINT = "/api/v1/application";

  const handleRejectCommentChange = (e) => {
    setRejectComment(e.target.value);
  };

  const fetchComments = () => {
    httprequest
      .get(`${COMMENTS_ENDPOINT}/${currentRow.id}/comments`)
      .then((res) => {
        const fetchedComments = res?.data?.data || [];

        // Combine comments and replies into a single array
        const combinedCommentsAndReplies = fetchedComments.flatMap(comment => {
          const commentEntry = { ...comment, type: "comment" };
          const replyEntries = comment.replies.map(reply => ({ ...reply, type: "reply", parentCommentId: comment.id }));

          return [commentEntry, ...replyEntries];
        });

        // Sort by the created_at field (chronologically)
        combinedCommentsAndReplies.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setComments(combinedCommentsAndReplies);
      })
      .catch((err) => {
        toast.error("Failed to fetch comments");
        console.error(err);
      });
  };

  const getEnrolmentDetails = () => {
    httprequest.get(`${ENROLMENT_ENDPOINT}/${id}`).then((res) => {
      setEnrolmentInfoData(res?.data?.data);
      setRequirePayment(res?.data?.data?.require_payment);
      fetchComments();
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  const handleExamDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  // -- | Get all applicants in the current enrollment
  const getApplicantInEnrolment = () => {
    httprequest.get(`${ENROLMENT_ENDPOINT}/applicants/${id}`).then((res) => {
      setApplicantsData(res?.data?.data);
      setApplicantsFilter(res?.data?.data);
      const applicant = res?.data?.data?.find(applicant => applicant.id === currentRow?.id);
      setHasPaid(applicant?.has_paid);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // eslint-disable-next-line no-console
  console.log(currentRow?.uploads?.file_url);
  const checkRowSelected = (e) => {
    if (e.selectedRows.length > 0) {
      setIsRowSelected(true);  // Row selected
    } else {
      setIsRowSelected(false); // No row selected
    }
    // eslint-disable-next-line no-console
    console.log("Selected Rows:", e.selectedRows);
  };
  const handleViewModal = (row) => {
    setInformationModal(!addinformationModal);
    setCurrentRow(row);
  };

  const handleDownloadModal = (row) => {
    setDownloadModal(!downloadModal);
    setCurrentRow(row);
  };
  const handleConvertModal = (row) => {
    setConvertModal(!convertModal);
    setCurrentRow(row);
  };
  // const handleDeleteModal = (row) => {
  //   setCurrentRow(row);
  //   setDeleteModal(!deleteModal);
  // };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleActionChange = (e) => {
    setSelectedAction(e.target.value);
    setActionModal(true);
  };

  const handleCloseActionModal = () => {
    setActionModal(false);
    setSelectedAction("");
    setSelectedAction("");
  };
  const closeConvert = () => {
    setConvertModal(false);
    setSelectedSession("");
  };
  const rejectAllApplications = () => {
    const applicationIds = applicantsData.map(applicant => applicant.id);
    if (!rejectComment) {
      toast.error("Please add a comment before rejecting.");

      return;
    }
    const payload = { application_ids: applicationIds, rejection_comment: rejectComment, application_status: "rejected" };

    httprequest.patch("/api/v1/enrolment/applications/batch", payload)
      .then(() => {
        toast.success("Applications rejected successfully");
        getApplicantInEnrolment();
        setRejectComment("");
        handleCloseActionModal();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to reject applications");
      });
  };
  const inviteAllForExamination = () => {
    const applicationIds = applicantsData.map(applicant => applicant.id); // Collect all application IDs
    const payload = {
      application_ids: applicationIds,
      exam_invite: true,
      exam_info: {
        date: formData.examDate,
        venue: formData.venue,
        time: formData.examTime
      }
    };

    httprequest.patch("/api/v1/enrolment/applications/batch", payload)
      .then(() => {
        toast.success("Invitations sent successfully");
        getApplicantInEnrolment();
        handleCloseActionModal();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to send invitations");
      });
  };

  const convertAllApplications = async () => {
    const applicationIds = applicantsData.map(applicant => applicant.id);
    const session = selectedSession;

    httprequest.post("/api/v1/enrolment/applications/convert", {
      application_ids: applicationIds,
      session_id: session
    })
      .then(() => {
        toast.success("Applications converted successfully");
        handleCloseActionModal(); // Close the modal
      })
      .catch((err) => {
        console.error("Conversion error:", err); // Debug log
        toast.error(err?.response?.data || "Failed to convert applications");
      });
  };

  const handleAction = () => {
    if (selectedAction === "reject_all") {
      rejectAllApplications();
    } else if (selectedAction === "invite_exam") {
      inviteAllForExamination();
    } else if (selectedAction === "convert_all") {
      convertAllApplications();
    }
  };

  const convertApplication = () => {
    const application_ids = [currentRow.id];
    const session_id = selectedSession.value;
    console.log(session_id);
    console.log("Application IDs:", application_ids); // Debug log

    if (application_ids.length === 0) {
      toast.error("No applications selected to convert.");

      return;
    }

    const payload = { application_ids, session_id };

    httprequest.post("/api/v1/enrolment/applications/convert", payload)
      .then(() => {
        setActionModal(false);
        setConvertModal(false);
        toast.success("Application converted successfully");
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to convert application");
      });
  };
  // const handleAddComment = () => {
  //   const applicationIds = currentRow?.id;

  //   if (!applicationIds) {
  //     toast.error("No applications selected to add a comment.");

  //     return;
  //   }

  //   const existingComments = currentRow?.administrator_comment || [];

  //   const updatedComments = [...existingComments, newComment];

  //   const payload = { administrator_comment: updatedComments };

  //   httprequest
  //     .patch(`/api/v1/enrolment/comment/${applicationIds}`, payload)
  //     .then(() => {
  //       toast.success("Comment added successfully");
  //       setNewComment(""); // Clear the input after success
  //       getApplicantInEnrolment(); // Refresh data to see the updated comment
  //       setMessageModal(false); // Close the modal after submission
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data || "Failed to add comment");
  //     });
  // };

  const handleAddComment = () => {
    const applicationId = currentRow.id;
    if (!applicationId) {
      toast.error("No applications selected to add a comment.");

      return;
    }

    // Retrieve existing comments
    // const existingComments = profile.administrator_comment || [];

    // Append the new comment to the existing comments
    const updatedComments = newComment;

    const payload = { comment: updatedComments, application_id: applicationId };

    httprequest.post("/api/v1/application/comments/admin", payload)
      .then(() => {
        toast.success("Comment added successfully");
        setNewComment("");
        fetchComments();
        setComments();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to add comment");
      });
  };
  const handleReplyComment = () => {
    const replyId = replyToCommentId;
    if (!replyId || !replyComment) {
      toast.error("No comment or reply text provided.");

      return;
    }

    const payload = { reply: replyComment, comment_id: replyId };
    httprequest.patch("/api/v1/application/comments/admin", payload)
      .then(() => {
        toast.success("Reply added successfully");
        setReplyComment("");
        setReplyToCommentId(null);
        fetchComments();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to add reply");
      });
  };
  const handleReplyClick = (commentId) => {
    setReplyToCommentId(commentId);
    setReplyComment("");
  };
  const handleCancelReply = () => {
    setReplyToCommentId(null);
    setReplyComment("");
  };
  const handleDownload = async () => {
    const url = currentRow?.uploads?.[0]?.file_url;

    if (url) {
      const loadingTask = getDocument(url);
      loadingTask.promise.then(
        (pdf) => {
          pdf.getPage(1).then((page) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const viewport = page.getViewport({ scale: 1 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport
            };
            page.render(renderContext).promise.then(() => {
              canvas.toBlob((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "downloaded_file.pdf"; // Set a default filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }, "application/pdf");
            });
          });
        },
        (reason) => {
          console.error(`Error loading PDF: ${reason}`);
        }
      );
    } else {
      console.error("No file URL found for download.");
    }
  };
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await httprequest.get("/api/v1/school-plan/session");
        const session = response?.data?.data;
        setSessionOptions(session);
        console.log(session);
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    fetchSessions();
  }, []);

  const handleSessionOptionChange = (selectedOption) => {
    setSelectedSession(selectedOption);
    console.log("Selected session:", selectedOption);
  };
  const formatTime = (dateString) => {
    const dateObject = new Date(dateString);

    const day = dateObject.getUTCDate().toString().padStart(2, "0");
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
    const hours = dateObject.getUTCHours().toString().padStart(2, "0");
    const minutes = dateObject.getUTCMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}  ${month}/${day}`;
  };
  const handleSetMessageModal = (row) => {
    fetchComments(row);
    setMessageModal(!messageModal);
    setCurrentRow(row);
  };
  useEffect(() => {
    getEnrolmentDetails();
    fetchComments();
    getApplicantInEnrolment();
  }, [currentRow]);
  const getTodayDate = () => {
    const today = new Date();

    const year = today.getFullYear();

    // Months are zero-based in JavaScript
    const month = String(today.getMonth() + 1).padStart(2, "0");

    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const columns = [
    {
      name: "Name",
      selector: row => row?.first_name + " " + row?.last_name
    },
    {
      name: "Email Address",
      selector: row => row?.email
    },
    {
      name: "Class",
      selector: row => row?.class?.name
    },
    {
      name: "Parent/Guardian",
      selector: row => `${row?.guardian_info?.first_name} ${row?.guardian_info?.last_name}`
    },
    {
      name: "Contact",
      selector: row => row?.phone
    },
    {
      name: "Application No",
      selector: row => row?.application_no
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.application_status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            onClick={() => {
              handleConvertModal(row);
            }}
            className="table-action-icon"
          >
            <Icon icon="mdi:account-convert" />
          </button>
          <button
            onClick={() => {
              handleViewModal(row);
            }}
            className="table-action-icon"
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            onClick={() => {
              handleDownloadModal(row);
            }}
            className="table-action-icon"
          >
            <Icon icon="ic:outline-folder" />
          </button>
          <button
            onClick={() => {
              handleSetMessageModal(row);
            }}
            className="table-action-icon"
          >
            <Icon icon="material-symbols-light:comment-sharp" />
          </button>

          {/* <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button> */}
        </div>
      )
    }
  ];

  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = applicantsData?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                      data?.last_name
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                      data?.guardian_info?.first_name
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                        data?.guardian_info?.first_name
                          ?.toLowerCase()
                          .includes(word.toLowerCase()) ||
                        data?.application_status
                          ?.toLowerCase()
                          .includes(word.toLowerCase())
    )
    );
    setApplicantsFilter(result);
  };

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/enrolments"
              link_one_name="Enrolments"
              active="Enrolment Information"
              description={
                <>
                  <div>See all the details about your enrolment and applications in it</div>
                  <div style={{ marginTop: "5px" }}>Classes: {enrolmentInfoData?.classes?.map((clss, i) => <span className={classes.enrolinfoclass} key={i}> {clss?.class_name}</span>)}</div>
                </>}
            />
          </div>
        </div>
        <div className="page-content">
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.enrolmentdetailsheader} style={{ backgroundImage: `url(${enrolmentInfoData?.banner?.file_url || EnrolBanner})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
              </div>
            </Grid>

          </Grid>
          <Table data={applicantsFilter} columns={columns} subHeader={true} handleRowSelected={checkRowSelected} pagination subHeaderComponent={
            <div className="action_div">
              {applicantsData?.length > 0 && (
                <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
              )}
              {isRowSelected && (  // Conditionally render dropdown based on row selection
                <div>
                  <SelectGroup
                    children={
                      <select
                        name="type"
                        value={selectedAction}
                        onChange={handleActionChange}
                      >
                        <option value="">--Select Action---</option>
                        <option value="reject_all">Reject All Applications</option>
                        <option value="invite_exam">Invite All for Examination</option>
                        <option value="convert_all">Convert All</option>
                      </select>
                    }
                  />
                </div>
              )}
            </div>

          }
          />
          {/* {Additional Comment Modal */}
          <Modal
            title="Comments"
            subtitle={<div>View comments betweeen you and your applicants</div>}
            modalContent={
              <>
                {Array.isArray(comments) && comments.length > 0 ? (
                  <div className="comment_div application_comment">
                    {comments.map((item, index) => (
                      <div key={index} className={`admin_comment ${item.ref_type}`}>
                        {item.type === "comment" ? (
                          <div className="comment_main">

                            <div className="apply comment_container">
                              <p className=" no_margin">
                                <strong>{item.user.name}</strong>
                              </p>
                              <span className="admin_comment_span">{item.comment}
                                <div className="comment_time justify_end">{formatTime(item.created_at)}</div>

                              </span>
                              {item.ref_type === "applicant" && (
                                <span
                                  className="reply"
                                  onClick={() => handleReplyClick(item.id)}
                                >
               Reply
                                </span>

                              )}
                            </div>
                          </div>
                        ) : (
                          <div className={`reply_container_${comments.find(comment => comment.id === item.parentCommentId)?.ref_type}`}>
                            <div className="reply_main">
                              <p className="reply-user  no_margin">
                                <strong>{item.user.name}</strong>
                              </p>
                              <div className="original-comment">
                                <div className="reply_com">
                                  <p className="original-comment-user no_margin">
                                    <strong>{comments.find(comment => comment.id === item.parentCommentId)?.user.name}</strong>
                                  </p>
                                  <span>
                                    {comments.find(comment => comment.id === item.parentCommentId)?.comment}
                                  </span>
                                </div>
                                <span>
                                  {item.reply}
                                </span>
                                <div className="comment_time">{formatTime(item.created_at)}</div>
                              </div>

                              {comments.find(comment => comment.id === item.parentCommentId)?.ref_type === "admin" && (
                                <span
                                  className="reply justify_end"
                                  onClick={() => handleReplyClick(comments.find(comment => comment.id === item.parentCommentId)?.id)}
                                >
  Reply
                                </span>
                              )}

                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no_upload">
                    <img src={nocomment} />
                    <span>No comment available</span>
                  </div>
                )}

                {/* Default comment submission area, hidden when replying */}
                {!replyToCommentId && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      margin="normal"
                      inputType="text"
                      inputName="newComment"
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)} // Handle input change
                    />
                    {/* <button className="upload_comment flex justify_end" onClick={handleAddComment}>
Submit Comment <Icon icon="zondicons:send" />
                    </button> */}
                  </Grid>
                )}

                {/* Reply form, shown only when replying to a comment */}
                {replyToCommentId && (
                  <div className="reply_div">
                    <div className="selected-comment-to-reply">
                      <div>
                        <span>
                          {comments.find(comment => comment.id === replyToCommentId)?.user.name}
                        </span>
                        <p className="no_margin">
                          {comments.find(comment => comment.id === replyToCommentId)?.comment}
                          {comments.find(comment => comment.id === replyToCommentId)?.replies.reply}
                        </p>
                      </div>
                      <button className="cancel_reply" onClick={handleCancelReply}>
                        <Icon icon="ic:outline-cancel" />
                      </button>
                    </div>

                    {/* Reply text area */}
                    <TextAreaGroup
                      margin="normal"
                      inputType="text"
                      inputName="replyComment"
                      value={replyComment}
                      onChange={(e) => setReplyComment(e.target.value)}
                    />
                    {/* <button className="upload_comment flex justify_end" onClick={handleReplyComment}>
Reply Comment <Icon icon="zondicons:send" />
                    </button> */}
                  </div>
                )}
              </>

            }
            modalFooter={
              <Grid container spacing={2} className="input-padding">
                {!replyToCommentId && (
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                      variant="primary"
                      buttonSize="full"
                      color="btndefault"
                      buttonName="Add Comment"
                      onClick={handleAddComment}
                    />
                  </Grid>)}
                {replyToCommentId && (
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                      variant="primary"
                      buttonSize="full"
                      color="btndefault"
                      buttonName="Reply Comment"
                      onClick={handleReplyComment}
                    />
                  </Grid>)}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Button
                    variant="dangeroutline"
                    buttonSize="full"
                    color="btndanger"
                    buttonName="Cancel"
                    onClick={() => setMessageModal(false)}
                  />
                </Grid>
              </Grid>
            }
            isopen={messageModal}
            closeModal={() => setMessageModal(false)}
          />
          {/* //Convert icon modal */}
          <Modal
            title="Convert Application"
            subtitle={<div><p>Are you sure you want to convert this applicant into a student?</p>
              <Grid container className="input-padding">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="multiple-select">
                    <p className="input-picker-title">Select Session</p>
                    <Select
                      name="session-option"
                      options={sessionOptions.map((option) => ({
                        label: option.session,
                        value: option.id
                      }))}
                      value={selectedSession}
                      onChange={handleSessionOptionChange}
                    />
                  </div>
                </Grid>
              </Grid> </div>}
            modalContent={
              <></>

            }
            modalFooter={
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Button
                    variant="success"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Convert"
                    onClick={convertApplication}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Button
                    variant="dangeroutline"
                    buttonSize="full"
                    color="btndanger"
                    buttonName="Cancel"
                    onClick={closeConvert}
                  />
                </Grid>
              </Grid>
            }
            isopen={convertModal}
            closeModal={() => setConvertModal(false)}
          />
          {/* Action Modal */}
          <Modal
            title={
              selectedAction === "reject_all"
                ? "Reject All Selected Applications"
                : selectedAction === "invite_exam"
                  ? "Invite All Selected for Examination"
                  : "Convert All Selected Applications"
            }
            subtitle={
              selectedAction === "reject_all" ? (
                <div>Are you sure you want to reject selected applications? </div>
              ) : selectedAction === "invite_exam" ? (
                <div>
                    You are about to invite all applicants for an examination.
                </div>
              ) : (
                <div>
                    Are you sure you want to Convert Application to Student?
                </div>
              )
            }
            modalContent={
              selectedAction === "invite_exam" ? (
                <>
                  <Grid container className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="multiple-select">
                        <InputGroup
                          inputType="text"
                          label="Select Venue"
                          inputName="venue"
                          value={formData.venue}
                          onChange={handleExamDataChange}
                          required
                        />
                      </div>
                      <div className="input-padding">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <InputGroup
                            inputType="date"
                            label="Exam Date"
                            inputName="examDate"
                            value={formData.examDate}
                            onChange={handleExamDataChange}
                            required
                            inputMin={getTodayDate()}
                          />
                        </Grid>
                      </div>
                      <div className="input-padding">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <InputGroup
                            inputType="time"
                            label="Exam Time"
                            inputName="examTime"
                            value={formData.examTime}
                            onChange={handleExamDataChange}
                            required
                          />
                        </Grid>
                      </div>
                    </Grid>

                  </Grid>
                </>
              ) : selectedAction === "convert_all" ? (
                <>
                  <Grid container className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="multiple-select">
                        <SelectGroup
                          label="Select Sessions"
                          children={
                            <select
                              value={selectedSession}
                              name="select-custom"
                              onChange={(e) => handleSessionOptionChange(e.target.value)}
                            >
                              <option disabled value="">
                                --Select Options---
                              </option>
                              {sessionOptions.map((item) => (
                                <option key={item.value} value={item.id}>
                                  {item.session}
                                </option>
                              ))}
                            </select>
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </>
              ) : selectedAction === "reject_all" ? (
                <div className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      inputType="text"
                      label="Add Comment"
                      inputName="reject_comment"
                      required
                      value={rejectComment}
                      onChange={handleRejectCommentChange}
                    />
                  </Grid>
                </div>
              ) : null
            }
            modalFooter={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                      variant={ selectedAction === "reject_all"
                        ? "danger"
                        : selectedAction === "invite_exam"
                          ? "primaryoutline"
                          : "primaryoutline"}
                      buttonSize="full"
                      color={selectedAction === "reject_all"
                        ? "btndefault"
                        : selectedAction === "invite_exam"
                          ? "btnfontprimary"
                          : "btnfontprimary"}
                      buttonName={
                        selectedAction === "reject_all"
                          ? "Reject All"
                          : selectedAction === "invite_exam"
                            ? "Invite All"
                            : "Convert All"
                      }
                      onClick={handleAction}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                      variant={ selectedAction === "reject_all"
                        ? "dangeroutline"
                        : selectedAction === "invite_exam"
                          ? "primary"
                          : "primary"}
                      buttonSize="full"
                      color={selectedAction === "reject_all"
                        ? "btndanger"
                        : selectedAction === "invite_exam"
                          ? "btndefault"
                          : "btndefault"}
                      buttonName="Cancel"
                      onClick={handleCloseActionModal}
                    />
                  </Grid>
                </Grid>
              </>
            }
            isopen={actionModal}
            closeModal={handleCloseActionModal}
          />

          {/* Application Information Modal */}
          <Modal
            title="Application Information"
            subtitle={
              <div>You can edit the information of the applicant below.
              </div>}
            modalContent={
              <>
                <div className="flex gap-10 align-items-center margin-top">
                  {/* <div className={classes.applicationdisplaypic}> */}
                  <div className={classes.displaypic}>
                    {currentRow?.display_pic?.file_url ? (
                      <img className="displaypic" src={currentRow?.display_pic?.file_url} alt="Display" />
                    ) : (
                      <Icon icon="radix-icons:person" />
                    )}
                  </div>

                  <div>
                    <h5 className={`${styles.noMargin}`}>{`${currentRow?.first_name + " " + currentRow?.last_name}`}</h5>
                    <p className={`${styles.noMargin}`}>{`Application No: ${currentRow?.application_no}`}</p>
                    {requirePayment && (
                      <p className={`${styles.noMargin}`}>Payment Status: {hasPaid ? "Paid" : "Not Paid"}</p>
                    )}
                  </div>
                  {/* </div> */}
                </div>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>

                    <div className="key-pair-container">
                      <div className="key-pair-content">
                        <p className="key-pair-name">Full Name</p>
                        <h3 className="key-pair-value">{currentRow?.first_name + " " + currentRow?.last_name}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Class Applying to</p>
                        <h3 className="key-pair-value">{currentRow?.class?.name}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Examination Status</p>
                        <h3 className="key-pair-value">{currentRow?.exam_invite === false ? "Not Invited" : "Invited"}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Email Address</p>
                        <h3 className="key-pair-value">{currentRow?.email}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Phone Number</p>
                        <h3 className="key-pair-value">{currentRow?.phone}</h3>
                      </div>
                      <h3>Guardian Information</h3>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Guardian Name</p>
                        <h3 className="key-pair-value">{currentRow?.guardian_info?.first_name + " " + currentRow?.guardian_info?.last_name}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Guardian Phone</p>
                        <h3 className="key-pair-value">{currentRow?.guardian_info?.phone}</h3>
                      </div>
                      <div className="key-pair-content">
                        <p className="key-pair-name">Relationship</p>
                        <h3 className="key-pair-value">{currentRow?.guardian_info?.relationship}</h3>
                      </div>
                      {currentRow?.custom_fields && (
                        <>
                          <h3>Additional Fields</h3>
                          {Object.keys(currentRow.custom_fields).map((key) => (
                            <div key={key} className="key-pair-content">
                              <p className="key-pair-name">{currentRow.custom_fields[key].label}</p>
                              <h3 className="key-pair-value">{currentRow.custom_fields[key].value}</h3>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>

                {/* {currentRow?.application_status !== "accepted" ? (
                  <Grid container className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Button
                        onClick={rejectApplication}
                        variant="dangeroutline"
                        buttonSize="full"
                        color="btndanger"
                        buttonName="Reject Application"
                      />
                    </Grid>
                    <Grid className="input-padding" item lg={12} md={12} sm={12} xs={12}>
                      <Button
                        onClick={convertApplication}
                        variant="success"
                        buttonSize="full"
                        color="btndefault"
                        buttonName="Convert"
                      />
                    </Grid>

                  </Grid>

                ) : (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button
                      onClick={rejectApplication}
                      variant="dangeroutline"
                      buttonSize="full"
                      color="btndanger"
                      buttonName="Reject Application"
                    />
                  </Grid>
                )} */}
              </>
            }
            isopen={addinformationModal}
            closeModal={handleViewModal}
          />
          {/* Result Modal */}
          <Modal
            title="View Uploads"
            subtitle={
              <div>Click on Each File to view the document
              </div>}
            modalContent={
              <>
                <Grid container spacing={2}>
                  {currentRow?.uploads && currentRow.uploads.length > 0 ? (
                    currentRow.uploads.map((upload, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                          >
                            <Typography>{`File ${index + 1}: ${upload.file_name || "Unknown File Name"}`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              {upload.file_url ? (
                                <Viewer fileUrl={upload.file_url} />
                              ) : (
                                <Typography>No file available</Typography>
                              )}
                            </Worker>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    ))
                  ) : (
                    <Typography>No uploads available for this candidate.</Typography>
                  )}
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Download Files"
                    onClick={handleDownload}
                  />
                </div>
              </>
            }
            isopen={downloadModal}
            closeModal={handleDownloadModal}
          />
          {/* Delete Modal */}
          <Modal
            title="Delete Student Information"
            subtitle={
              <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.name}</span>. If deleted, employee will no longer have access to this Informations.`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <Button variant="danger" buttonName="Delete Information" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
        </div>
        <ToastContainer />
      </>
    }
    />
  );
};

export default EnrolmentDetails;
