/* eslint-disable prefer-template */
import useStyles from "../styles";
import { Grid } from "@mui/material";
import styles from "../components/Guardian.module.css";
import { useState, useEffect } from "react";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
// import { useParams } from "react-router-dom";

const STUDENTS_ENDPOINT = "/api/v1/students";
// const GUARDIAN_ENDPOINT = "/api/v1/guardian";

const PersonalInfo = ({ data }) => {
  const classes = useStyles();
  // const { id } = useParams();
  const [multipleStudents, setMultipleStudents] = useState([]);
  // const [defaultStudents, setDefaultStudent] = useState([]);
  const [offboardModal, setOffboardModal] = useState(false);

  const handleOffBoardModal = () => {
    setOffboardModal(!offboardModal);
  };

  const handleCloseOffBoard = () => {
    setOffboardModal(false);
  };

  const [personalDetails, setPersonalDetails] = useState({
    first_name: data?.first_name,
    last_name: data?.last_name,
    email: data?.email,
    gender: data?.gender,
    job_title: data?.job_title,
    address: data?.address,
    display_pic: data?.display_pic?.file_url,
    contact: data?.contact,
    wards: []
  });

  const handleMultipleWardChange = (event) => {
    const selectedValue = event.map((item) => item.value);

    setPersonalDetails({ ...personalDetails, wards: selectedValue });
  };

  const getAllStudents = () => {
    httprequest.get(STUDENTS_ENDPOINT).then((res) => {
      setMultipleStudents(res?.data?.data?.map((student) => {
        return {
          label: student?.first_name + " " + student?.last_name,
          value: student.student_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    getAllStudents();
  }, []);

  // const getCurrentWard = () => {
  //   httprequest.get(`${GUARDIAN_ENDPOINT}/wards/${id}`).then((res) => {
  //     setDefaultStudent(personalDetails?.wards?.map((student) => {
  //       return {
  //         label: student?.first_name + " " + student?.last_name,
  //         value: student.student_id
  //       };
  //     }));
  //   }).catch(() => {
  //   });
  // };

  // useEffect(() => {
  //   getCurrentWard();
  // }, []);
  useEffect(() => {
    // Get all student data to prefill the multi student drop down
    // getAllStudents();
    setPersonalDetails({
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      gender: data?.gender,
      job_title: data?.job_title,
      address: data?.address,
      display_pic: data?.display_pic?.file_url,
      contact: data?.contact,
      wards: data?.wards?.map((student) => {
        return {
          label: student?.name,
          value: student.id
        };
      })
    });
  }, [data]);

  const { first_name, last_name, email, address, contact, gender, job_title, wards } = personalDetails;

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setPersonalDetails({ ...personalDetails, [name]: value });
  };

  return (
    <Grid container spacing={4}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <div className={`${styles.border} left-container`}>
          <h4>Personal Information</h4>
          <h6>Personal Information of Guardian</h6>
          <div className={classes.profilecontainer}>
            <div className={classes.imageviewer} style={{ backgroundImage: `url(${data?.display_pic?.file_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

            </div>
          </div>
          <Button variant="primaryoutline" color="btnfontprimary" buttonSize="full" buttonName="off board guardian" onClick={handleOffBoardModal} />
        </div>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <div className={`${styles.border} right-container`}>
          <Grid container spacing={2} className={classes.profileform}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="First name" name="first_name" defaultValue={first_name} value={first_name} onChange={handleDetailsChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Last name" name="last_name" defaultValue={last_name} value={last_name} onChange={handleDetailsChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Email address" name="email" defaultValue={email} value={email} onChange={handleDetailsChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Gender" name="gender" defaultValue={gender} value={gender} onChange={handleDetailsChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Contact" name="contact" defaultValue={contact} value={contact} onChange={handleDetailsChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Address" name="address" defaultValue={address} value={address} onChange={handleDetailsChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup inputType="text" label="Job Title" name="job_title" defaultValue={job_title} value={job_title} onChange={handleDetailsChange} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className="multiple-select">
                <p>Wards / Children</p>
                <Select isMulti name="wards"
                  closeMenuOnSelect={true} defaultValue={wards?.map((student) => student)} options={multipleStudents} onChange={handleMultipleWardChange}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Modal
        title="Off board guardian"
        subtitle="Are you sure you want to off board Eugene Adavore. If leave off boarded, employee will no longer have access to this platform."
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <Button variant="danger" buttonName="Off board Guardian" buttonSize="full" color="btndefault" />
          </>
        }
        isopen={offboardModal}
        closeModal={handleCloseOffBoard}
      />
      <ToastContainer />
    </Grid>
  );
};

export default PersonalInfo;
