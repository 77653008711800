import useStyles from "./styles";
import classNames from "classnames";

const Badge = ({ status }) => {
  const classes = useStyles({ status });

  const statusText = status === true ? "Active" :
    status === false ? "Inactive" :
      status === "on-leave" ? "On Leave" :
        status === "Deactivated" ? "Deactivated" :
          status === "virtual" ? "Virtual" :
            status === "physical" ? "Physical" :
              status === "declined" ? "Declined" :
                status === "high" ? "High" :
                  status === "medium" ? "Medium" :
                    status === "low" ? "Low" :
                      status === "closed" ? "Closed" :
                        status === "open" ? "Open" :
                          status === "unread" ? "Unread" :
                            status === "requested" ? "Leave Requested" :
                              status === "primary" ? "Primary" :
                                status === "secondary" ? "Secondary" :
                                  status === "leave-requested" ? "Leave Requested" :
                                    status === "expired" ? "Expired" :
                                      status === "approved" ? "Approved" :
                                        status === "active" ? "Active" :
                                          status === "inactive" ? "InActive" :
                                            status === "used" ? "Used" :
                                              status === "revoked" ? "Revoked" :
                                                status === "available" ? "Available" :
                                                  status === "unavailable" ? "Unavailable" :
                                                    status === "In review" ? "In Review" :
                                                      status === "book" ? "Book" :
                                                        status === "video" ? "Video" :
                                                          status === "archived" ? "Archived" :
                                                            status === "present-at-event" ? "Present at event" :
                                                              status === "accepted" ? "Accepted" :
                                                                status === "rejected" ? "Rejected" :
                                                                  status === "pending" ? "Pending" :
                                                                    status === "upcoming" ? "Upcoming" :
                                                                      status === "completed" ? "Completed" :
                                                                        status === "one-time" ? "One Time" :
                                                                          status === "permanent" ? "Permanent" :
                                                                            status === "pending-request" ? "Pending Request" : "";

  return (
    <div className={classNames(classes.badgewrapper, classes.bg)}>{statusText}</div>
  );
};
export default Badge;
