import Layout from "../../../components/Layout";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb";
import DetailsText from "../components/DetailsText";
// import useStyles from "../styles";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import Badge from "../../../components/Badge";
import SubjectsInClass from "./SubjectsInClass";
import StudentInClass from "./StudentsInClass";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";

const CLASS_ENDPOINT = "/api/v1/class";

const LeaveDetails = () => {
  // const classes = useStyles();

  const { id } = useParams();
  const [value, setValue] = useState("1");
  const [data, setData] = useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    httprequest.get(`${CLASS_ENDPOINT}/${id}`).then((res) => {
      setData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb link_one="/class-activities/classes" link_one_name="Classes" active="Class Details" description={`See the details of ${data?.class_name}`} />
            </div>
          </div>
          <div className="page-content">
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Class Details" value="1" />
                  <Tab label="Students in Class" value="2" />
                  <Tab label="Subjects" value="3" />
                </Tabs>
              </div>
              <TabPanel value="1" className="tabpanel-overide">
                <Grid container spacing={4}>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <DetailsText title="Name" subtitle={data?.class_name} />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <DetailsText title="Class Type" subtitle={<Badge status={data?.class_type} />} />
                    </Grid>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <DetailsText title="Class Teacher" subtitle={<Link to={`/employees/${data?.class_teacher_id}`}>{data?.class_teacher_name}</Link>} />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <DetailsText title="Created At" subtitle={data?.created_at?.substring(0, 10)} />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <DetailsText title="Updated At" subtitle={data?.updated_at?.substring(0, 10)} />
                  </Grid>
                  {data?.class_type === "virtual" ? (
                    <>
                      {data?.meeting_link && (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <DetailsText title="Meeting Link" subtitle={data?.meeting_link} />
                        </Grid>
                      )}
                      {data?.start_time && (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <DetailsText title="Start Time" subtitle={data?.start_time} />
                        </Grid>
                      )}
                      {data?.end_time && (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <DetailsText title="End Time" subtitle={data?.end_time} />
                        </Grid>
                      )}
                      {data?.virtual_class_date && (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <DetailsText title="Virtual Class Date" subtitle={data?.virtual_class_date} />
                        </Grid>
                      )}
                      {data?.attendees && (
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                          <DetailsText title="Attendees" subtitle={data?.attendees?.map((attendee) => {
                            return <li key={attendee}>{attendee}</li>;
                          })}
                          />
                        </Grid>
                      )}
                    </>
                  ) : null}
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <StudentInClass />
              </TabPanel>
              <TabPanel value="3">
                <SubjectsInClass />
              </TabPanel>
            </TabContext>
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default LeaveDetails;
