import useStyles from "./styles";
import { Icon } from "@iconify/react/dist/iconify.js";

const NoticeBoardCard = ({ title, date, teacher, viewClick }) => {
  const classes = useStyles({ title });

  return (
    <div className={classes.noticecard}>
      <div className="text-align-left">
        <h5>Priority:  {title}</h5>
        <p><Icon icon="uil:calender" width="10px" height="10px" style={{ color: "black" }} />Added on: {date}</p>
      </div>
      <div className="text-align-right">
        <p>Added By:  {teacher}</p>
        <h4 onClick={viewClick}>View Notice</h4>
      </div>
    </div>
  );
};

export default NoticeBoardCard;
