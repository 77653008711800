import { makeStyles } from "@mui/styles";
import tinycolor from "tinycolor2";

export default makeStyles((theme) => ({

  badgewrapper: {
    borderRadius: "12px",
    minWidth: 120,
    minHeight: 25,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  bg: {
    color: ({ status }) => {
      return (
        status === true ? theme.palette.primary.main :
          status === false ? "#CB1A14" :
            status === "on-leave" ? "#DD900D" :
              status === "approved" ? "#099137" :
                status === "virtual" ? "#667185" :
                  status === "physical" ? "#099137" :
                    status === "inactive" ? "#CB1A14" :
                      status === "inactive" ? "#CB1A14" :
                        status === "In review" ? "#0065FF" :
                          status === "closed" ? "#CB1A14" :
                            status === "medium" ? "#0065FF" :
                              status === "high" ? "#CB1A14" :
                                status === "declined" ? "#CB1A14" :
                                  status === "low" ? "#101928" :
                                    status === "used" ? "#101928" :
                                      status === "open" ? "#099137" :
                                        status === "unread" ? "#099137" :
                                          status === "active" ? "#099137" :
                                            status === "archived" ? "#CB1A14" :
                                              status === "unavailable" ? "#CB1A14" :
                                                status === "available" ? "#099137" :
                                                  status === "book" ? "#099137" :
                                                    status === "video" ? "#CB1A14" :
                                                      status === "requested" ? "#667185" :
                                                        status === "pending" ? "#0065FF" :
                                                          status === "pending" ? "#0065FF" :
                                                            status === "upcoming" ? "#0065FF" :
                                                              status === "completed" ? "#099137" :
                                                                status === "present-at-event" ? "#0A17A7" :
                                                                  status === "accepted" ? "#099137" :
                                                                    status === "rejected" ? "#CB1A14" :
                                                                      status === "leave-requested" ? "#667185" :
                                                                        status === "one-time" ? "#04172B" :
                                                                          status === "permanent" ? "#101928" :
                                                                            status === "pending-request" ? "#DD900D" : "#667185"
      );
    },
    backgroundColor: ({ status }) => {
      return (
        status === true ? tinycolor(theme.palette.primary.main).lighten(45).toHexString() :
          status === false ? tinycolor("#CB1A14").lighten(45).toHexString() :
            status === "on-leave" ? tinycolor("#DD900D").lighten(45).toHexString() :
              status === "Deactivated" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                status === "approved" ? tinycolor("#099137").lighten(45).toHexString() :
                  status === "physical" ? tinycolor("#099137").lighten(45).toHexString() :
                    status === "virtual" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                      status === "declined" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                        status === "high" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                          status === "medium" ? tinycolor("#0065FF").lighten(45).toHexString() :
                            status === "low" ? tinycolor("#101928").lighten(45).toHexString() :
                              status === "closed" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                                status === "used" ? tinycolor("#6C737F").lighten(45).toHexString() :
                                  status === "open" ? tinycolor("#099137").lighten(45).toHexString() :
                                    status === "unread" ? tinycolor("#099137").lighten(45).toHexString() :
                                      status === "active" ? tinycolor("#099137").lighten(45).toHexString() :
                                        status === "inactive" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                                          status === "In review" ? tinycolor("#0065FF").lighten(45).toHexString() :
                                            status === "unavailable" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                                              status === "available" ? tinycolor("#099137").lighten(45).toHexString() :
                                                status === "video" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                                                  status === "book" ? tinycolor("#099137").lighten(45).toHexString() :
                                                    status === "leave-requested" ? tinycolor("#667185").lighten(45).toHexString() :
                                                      status === "pending" ? tinycolor("#0065FF").lighten(45).toHexString() :
                                                        status === "upcoming" ? tinycolor("#0065FF").lighten(45).toHexString() :
                                                          status === "completed" ? tinycolor("#099137").lighten(45).toHexString() :
                                                            status === "accepted" ? tinycolor("#099137").lighten(45).toHexString() :
                                                              status === "rejected" ? tinycolor("#CB1A14").lighten(45).toHexString() :
                                                                status === "present-at-event" ? tinycolor("#667185").lighten(45).toHexString() :
                                                                  status === "permanent" ? tinycolor("#E8562E").lighten(35).toHexString() :
                                                                    status === "one-time" ? tinycolor("#667185").lighten(45).toHexString() :
                                                                      status === "pending-request" ? tinycolor("#DD900D").lighten(45).toHexString() :
                                                                        tinycolor("#667185").lighten(45).toHexString()
      );
    }
  }
}));
