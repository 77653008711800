import AccessComponent from "../../../components/AccesControl/AccessComponent";
import { Grid } from "@mui/material";

const AccessControl = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <AccessComponent name="Eugene ADAVORE" usecase="Unique Code generated for Eugene ADAVORE is 03s01v3" mailaddress="Ewaoluwa@gmail.com" accesscontrol="Pick up Euguene" phonenumber="(+234)909083445567" codestatus="Active" />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <AccessComponent name="Lekan Jr" usecase="Unique Code generated for Jr is 07s03v3" mailaddress="Daddy@gmail.com" phonenumber="(+234)909083445567" accesscontrol="Deliver a package" codestatus="In-Active" />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <AccessComponent name="Eugene ADAVORE" usecase="Unique Code generated for Jr is 04s01v3" mailaddress="daddy@gmail.com" accesscontrol="Schduled Check up" phonenumber="(+234)909083445567" codestatus="pending" />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <AccessComponent name="Eugene ADAVORE" usecase="Unique Code generated for Eugene ADAVORE is 03s01v3" mailaddress="Ewaoluwa@gmail.com" accesscontrol="Schduled Check up" phonenumber="(+234)909083445567" codestatus="Active" />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <AccessComponent name="Lekan Jr" usecase="Unique Code generated for Jr is 07s03v3" mailaddress="Daddy@gmail.com" phonenumber="(+234)909083445567" accesscontrol="Pick up Euguene" codestatus="In-Active" />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <AccessComponent name="Eugene ADAVORE" usecase="Unique Code generated for Jr is 04s01v3" mailaddress="daddy@gmail.com" phonenumber="(+234)909083445567" accesscontrol="Deliver a package" codestatus="pending" />
        </Grid>
      </Grid>
    </div>
  );
};

export default AccessControl;
