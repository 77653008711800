/* eslint-disable prefer-template */
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Modal from "../../../components/Modal";
import useStyles from "../styles";
import { Grid } from "@mui/material";
import { useUserState } from "../../../context/UserContext";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../../hooks/useValidator";

const SUBJECTS_ENDPOINT = "/api/v1/subjects";
const TEACHERS_ENDPOINT = "/api/v1/teachers";
const CLASSES_ENDPOINT = "/api/v1/class";
const CLASS_SUBJECT_ENDPOINT = "/api/v1/class-subject";

const EmployerSubject = () => {
  const classes = useStyles();
  const { profile } = useUserState();
  const [currentRow, setCurrentRow] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [value, setValue] = useState("1");
  const [deleteModal, setDeleteModal] = useState(false);
  const [subjectArr, setSubjectArr] = useState([""]);
  const [isCreating, setIsCreating] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [subjectsData, setSubjectsData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [classesData, setClassData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [subjectByClass, setSubjectByClass] = useState([]);
  const [subjectForm, setSubjectForm] = useState({
    name: subjectArr
  });
  const [editSubject, setEditSubject] = useState({
    name: ""
  });

  // --| handle change for tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [subjectValidator, showSubjectValidator] = useValidator();
  const [assignValidator, showAssignValidator] = useValidator();
  const [searchResult, setSearchResult] = useState([]);
  const [subjectResultByClass, setSearchResultByClass] = useState([]);
  const [editSubjectModal, setEditSubjectModal] = useState(false);
  const [assignsubjectmodal, setAssignSubjectModal] = useState(false);
  const [addsubjectModal, setSubjectModal] = useState(false);
  const [teacherArr, setTeacherArr] = useState([""]);
  const [assignForm, setAssignForm] = useState({
    subject_id: "",
    class_id: "",
    teacher_id: teacherArr
  });

  const handleEditSubject = (e) => {
    const { name, value } = e.target;
    setEditSubject({ ...editSubject, [name]: value });
  };

  const handleAddExtraTeacher = () => {
    const newTeacher = "";
    setTeacherArr([...teacherArr, newTeacher]);
    setAssignForm({ ...assignForm, teacher_id: [...teacherArr, newTeacher] });
  };

  const handleTeacherChange = (e, index) => {
    const { value } = e.target;
    const allTeacher = [...teacherArr];
    allTeacher[index] = value;
    setTeacherArr(allTeacher);
    setAssignForm({ ...assignForm, teacher_id: allTeacher });
  };

  const handleEditSubjectModal = (row) => {
    // eslint-disable-next-line no-console
    console.log(row, "Edit Row");
    setCurrentRow(row);
    setEditSubject({
      ...editSubject,
      name: row?.name
    });
    setEditSubjectModal(!editSubjectModal);
  };

  const handleCloseEditSubjectModal = () => {
    setCurrentRow({});
    setEditSubjectModal(!editSubjectModal);
    setEditSubject({
      ...editSubject,
      name: ""
    });
  };

  const handleDeleteTeacherItem = (index) => {
    const newTeacher = teacherArr.filter((_item, ind) => ind !== index);
    setTeacherArr(newTeacher);
    setAssignForm({ ...assignForm, teacher_id: newTeacher });
  };

  const handleCloseAssignTecherModal = () => {
    setAssignSubjectModal(false);
    setTeacherArr([""]);
    setSubjectForm({ subject_id: "", class_id: "", teacher_id: [""] });
  };

  const handleAssignChange = (e) => {
    const { name, value } = e.target;
    setAssignForm({ ...assignForm, [name]: value });
  };

  const handleSubjectChange = (e, index) => {
    const { value } = e.target;
    const allSubjects = [...subjectArr];
    allSubjects[index] = value;
    setSubjectArr(allSubjects);
    setSubjectForm({ ...subjectForm, name: allSubjects });
  };

  const AddAdditionalSubject = () => {
    const newSubject = "";
    setSubjectArr([...subjectArr, newSubject]);
    setSubjectForm({ ...subjectForm, name: [...subjectArr, newSubject] });
  };

  const handleDeleteSubjectItem = (index) => {
    const newSubject = subjectArr.filter((_item, ind) => ind !== index);
    setSubjectArr(newSubject);
    setSubjectForm({ ...subjectForm, name: newSubject });
  };

  const handleAssignSubjectModal = () => {
    setAssignSubjectModal(!assignsubjectmodal);
  };

  const handleAddSubjectModal = () => {
    setSubjectModal(!addsubjectModal);
  };

  const handleCloseAddSubjectModal = () => {
    setSubjectModal(!addsubjectModal);
    setSubjectArr([""]);
    setSubjectForm({ name: [""] });
  };

  const handleDeleteModal = (row) => {
    // eslint-disable-next-line no-console
    console.log(row, "Delete Row");
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const getAllSubjects = () => {
    httprequest
      .get(SUBJECTS_ENDPOINT)
      .then((res) => {
        setSubjectsData(res?.data?.data);
        setSearchResult(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getSubjectsClass = () => {
    httprequest
      .get(`${CLASS_SUBJECT_ENDPOINT}/${value}`)
      .then((res) => {
        setSubjectByClass(res?.data?.data);
        setSearchResultByClass(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // eslint-disable-next-line no-console
  console.log(subjectsData, "subjectsData");

  useEffect(() => {
    if (value !== "1") {
      getSubjectsClass();
    }
  }, [value]);

  const getAllTeachers = () => {
    httprequest
      .get(TEACHERS_ENDPOINT)
      .then((res) => {
        setTeacherData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getAllClasses = () => {
    httprequest
      .get(CLASSES_ENDPOINT)
      .then((res) => {
        const physicalClasses = res?.data?.data?.filter(
          (data) => data?.class_type === "physical"
        );
        setClassData(physicalClasses);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const handleCreateSubjects = () => {
    if (subjectValidator.allValid()) {
      setIsCreating(true);
      httprequest
        .post(SUBJECTS_ENDPOINT, subjectForm)
        .then((res) => {
          setIsCreating(false);
          toast.success(res?.data?.message);
          getAllSubjects();
          handleCloseAddSubjectModal();
        })
        .catch((err) => {
          setIsCreating(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showSubjectValidator(true);
    }
  };

  const handleEditASubject = () => {
    setIsEditing(true);
    httprequest.patch(`${SUBJECTS_ENDPOINT}/${currentRow?.id}`, editSubject).then((res) => {
      setIsEditing(false);
      toast.success(res?.data?.message);
      getAllSubjects();
      handleCloseEditSubjectModal();
    }).catch((err) => {
      setIsEditing(false);
      toast.error(err?.response?.data?.message);
    });
  };

  // Handle Delete Subject
  const handleDeleteSubject = () => {
    setIsDeleting(true);
    httprequest.delete(`${SUBJECTS_ENDPOINT}/${currentRow?.id}`).then((res) => {
      setIsDeleting(false);
      toast.success(res?.data?.message);
      handleCloseDeleteModal();
      getAllSubjects();
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const handleAssignSubject = () => {
    if (assignValidator.allValid()) {
      setIsAssigning(true);
      const { subject_id, teacher_id } = assignForm;
      httprequest
        .post(
          `${CLASS_SUBJECT_ENDPOINT}/assign-subject/${assignForm?.class_id}`,
          { subject_id, teacher_id }
        )
        .then((res) => {
          setIsAssigning(false);
          toast.success(res?.data?.message);
          getAllSubjects();
          getAllClasses();
          window.location.reload();
          handleCloseAssignTecherModal();
        })
        .catch((err) => {
          setIsAssigning(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showAssignValidator(true);
    }
  };

  useEffect(() => {
    getAllSubjects();
    getAllTeachers();
    getAllClasses();
  }, []);

  const columns = [
    {
      name: "Subject",
      selector: (row) => row?.name
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button onClick={() => handleEditSubjectModal(row)} className="table-action-icon">
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const subjectColumns = [
    {
      name: "Subject",
      selector: (row) => row?.subject_name
    },
    {
      name: "Assigned Teacher",
      selector: (row) => row?.teachers?.map((teacher) => teacher?.teacher_name)
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon">
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];
  // --| Filter Subject table using name, email and class
  const handleSearchSubject = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = subjectsData?.filter((data) => valArray?.every((word) => data?.name?.toLowerCase().includes(word.toLowerCase()) ||
          data?.status
            ?.toLowerCase().includes(word.toLowerCase()) ||
          data?.label?.toLowerCase().includes(word.toLowerCase()) ||
          data?.type?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  // --| Filter Subject table using name, email and class
  const handleSearchSubjectByClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = subjectByClass?.filter((data) => valArray?.every((word) => data?.teacher_name
      ?.toLowerCase().includes(word.toLowerCase()) ||
          data?.subject_name?.toLowerCase().includes(word.toLowerCase()) ||
          data?.label?.toLowerCase().includes(word.toLowerCase()) ||
          data?.type?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResultByClass(result);
  };

  return (
    <>
      <PageTitle
        title="All Subjects"
        subtitle="See all the subjects currently in your school, can and remove at your convenience."
        button={
          <div className="flex gap-10">
            <Button
              onClick={handleAssignSubjectModal}
              variant="primaryoutline"
              buttonSize="fluid"
              color="btnfontprimary"
              buttonName="Assign Subject"
            />
            <Button
              onClick={handleAddSubjectModal}
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              buttonName="Create Subject"
            />
          </div>
        }
      />
      <div className="page-content">
        <TabContext value={value}>
          <div className="tablist-container">
            <Tabs
              indicatorColor="none"
              className="overide-tablist"
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab label="All" value="1" data={profile} />
              {classesData?.map((data) => {
                return (
                  <Tab
                    key={data?.id}
                    value={data?.id}
                    label={data?.class_name}
                  />
                );
              })}
            </Tabs>
          </div>
          <TabPanel value="1" className="tabpanel-overide">
            <Table
              data={searchResult}
              columns={columns}
              subHeader={true}
              pagination
              subHeaderComponent={
                <TableSearch
                  placeholder="Search here..."
                  searchTableFunc={handleSearchSubject}
                />
              }
            />
          </TabPanel>
          {classesData?.map((data) => {
            return (
              <TabPanel key={data?.id} value={data?.id}>
                <Table
                  data={subjectResultByClass}
                  columns={subjectColumns}
                  subHeader={true}
                  pagination
                  subHeaderComponent={
                    <TableSearch
                      placeholder="Search here..."
                      searchTableFunc={handleSearchSubjectByClass}
                    />
                  }
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </div>
      {/* Assign Subject Modal */}
      <Modal
        title="Assign Subject"
        subtitle={
          <div>Assign a teacher to a subject in the selected class.</div>
        }
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectGroup
                  label="Subject"
                  required
                  children={
                    <select name="subject_id" onChange={handleAssignChange}>
                      <option value="">--- Select ---</option>
                      {subjectsData?.map((subject) => {
                        return (
                          <option key={subject.id} value={subject?.id}>
                            {subject?.name}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
                {assignValidator.message(
                  "subject_id",
                  assignForm?.subject_id,
                  "required"
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectGroup
                  label="Class"
                  required
                  children={
                    <select name="class_id" onChange={handleAssignChange}>
                      <option value="">--- Select ---</option>
                      {classesData?.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item?.class_name}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
                {assignValidator.message(
                  "class_id",
                  assignForm?.class_id,
                  "required"
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {teacherArr?.map((item, index) => (
                  <div key={index} className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <SelectGroup
                        label="Assign Teacher"
                        required
                        children={
                          <select
                            onChange={(e) => {
                              handleTeacherChange(e, index);
                            }}
                            value={item}
                          >
                            <option value="">--- Select ---</option>
                            {teacherData?.map((teacher) => {
                              return (
                                <option
                                  key={teacher.employee_id}
                                  value={teacher.employee_id}
                                >
                                  {teacher?.first_name +
                                    " " +
                                    teacher?.last_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {assignForm?.teacher_id?.length > 1 ? (
                        <p
                          className="delete-input"
                          onClick={() => {
                            handleDeleteTeacherItem(index);
                          }}
                        >
                          <Icon icon="zondicons:minus-solid" />
                          Remove this teacher
                        </p>
                      ) : null}
                    </Grid>
                  </div>
                ))}
              </Grid>
              <div
                onClick={handleAddExtraTeacher}
                className={`${classes.mail} flex align-items-center`}
              >
                <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                <p>Add another Teacher</p>
              </div>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button
                variant="primary"
                buttonSize="full"
                color="btndefault"
                buttonName="Assign Subject"
                isLoading={isAssigning}
                onClick={handleAssignSubject}
              />
            </div>
          </>
        }
        isopen={assignsubjectmodal}
        closeModal={handleAssignSubjectModal}
      />
      {/* Create Subject Modal */}
      <Modal
        title="Create Subject"
        subtitle={
          <div>
            Create a new subject and assign a teacher to teach the subject.
          </div>
        }
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {subjectArr?.map((item, index) => (
                  <div key={index}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Subject Name"
                        margin="normal"
                        required
                        placeHolder="Enter subject name"
                        inputType="text"
                        onChange={(e) => handleSubjectChange(e, index)}
                        value={item}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      {subjectForm?.name?.length > 1 ? (
                        <p
                          className="delete-input"
                          onClick={() => {
                            handleDeleteSubjectItem(index);
                          }}
                        >
                          <Icon icon="zondicons:minus-solid" />
                          Remove this subject
                        </p>
                      ) : null}
                    </Grid>
                  </div>
                ))}
              </Grid>
              <div
                className={`${classes.mail} flex align-items-center`}
                onClick={AddAdditionalSubject}
              >
                <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                <p>Add another subject</p>
              </div>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button
                variant="primary"
                buttonSize="full"
                color="btndefault"
                buttonName="Add Subject(s)"
                onClick={handleCreateSubjects}
                isLoading={isCreating}
              />
            </div>
          </>
        }
        isopen={addsubjectModal}
        closeModal={handleCloseAddSubjectModal}
      />
      {/* Edit Subject Modal */}
      <Modal
        title="Edit Subject"
        subtitle={
          <div>
            Edit subject{currentRow?.name}.
          </div>
        }
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Subject Name"
                  margin="normal"
                  required
                  placeHolder="Enter subject name"
                  inputType="text"
                  InputName="name"
                  onChange={handleEditSubject}
                  defaultValue={editSubject?.name}
                />
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button
                variant="primary"
                buttonSize="full"
                color="btndefault"
                buttonName="Edit Subject"
                onClick={handleEditASubject}
                isLoading={isEditing}
              />
            </div>
          </>
        }
        isopen={editSubjectModal}
        closeModal={handleCloseEditSubjectModal}
      />
      {/* Delete Modal */}
      <Modal
        title="Delete Subject"
        subtitle={
          <div>
            Are you sure you want to delete{" "}
            <span className="modal-name-highlight">{currentRow?.name}</span>.
            Deleting is a permanent action`
          </div>
        }
        modalContent={<></>}
        modalFooter={
          <>
            <Button
              variant="danger"
              buttonName="Delete Subject"
              buttonSize="full"
              color="btndefault"
              isLoading={isDeleting}
              onClick={handleDeleteSubject}
            />
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
      <ToastContainer />
    </>
  );
};

export default EmployerSubject;
