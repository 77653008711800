/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
import useStyles from "./styles";
import Button from "../../components/Button";
import PageTitle from "../../components/PageTitle";
import BreadCrumb from "../../components/BreadCrumb";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Icon } from "@iconify/react";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "../../components/Modal";
import { Grid } from "@mui/material";
import InputGroup from "../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../components/FormComponent/TextAreaGroup";
import PersonalInfo from "./components/PersonalInfo";
import AccessControl from "./components/AccessControl";
import TuitionFees from "./components/TuitionsFees";
import Ward from "./components/Ward";
import { httprequest } from "../../data/api";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const GUARDIAN_ENDPOINT = "/api/v1/guardian";
const STUDENTS_ENDPOINT = "/api/v1/students";

const GuardianDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [messageModal, setMessageModal] = useState(false);
  const [defaultStudents, setDefaultStudent] = useState([]);
  const [multipleStudents, setMultipleStudents] = useState([]);
  const [addreportModal, setReportModal] = useState(false);
  const [guardiandata, setGuardianData] = useState([]);
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMessageModal = () => {
    setMessageModal(!messageModal);
  };

  const handleReportModal = () => {
    setReportModal(!addreportModal);
  };

  useEffect(() => {
    httprequest.get(`${GUARDIAN_ENDPOINT}/${id}`).then((res) => {
      setGuardianData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  }, []);

  const getCurrentWard = () => {
    httprequest.get(`${GUARDIAN_ENDPOINT}/wards/${id}`).then((res) => {
      setDefaultStudent(res?.data?.data?.map((student) => {
        return {
          label: student?.first_name + " " + student?.last_name,
          value: student.student_id
        };
      }));
    }).catch(() => {
    });
  };

  const getAllStudents = () => {
    httprequest.get(STUDENTS_ENDPOINT).then((res) => {
      setMultipleStudents(res?.data?.data?.map((student) => {
        return {
          label: student?.first_name + " " + student?.last_name,
          value: student.student_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    getCurrentWard();
    getAllStudents();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/users/guardians"
                link_one_name="Guardians"
                active={`${guardiandata?.first_name + " " + guardiandata?.last_name}`}
                description="See the details of the guardian"
              />
            </div>
          </div>
          <div className={classes.pageContent}>
            <PageTitle className={classes.inline}
              title={`${guardiandata?.first_name + " " + guardiandata?.last_name}`}
              subtitle="View details about Guardian"
              button={
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    onClick={handleMessageModal}
                    buttonName="Send a message to Guardian"
                  />
                  {/* <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    onClick={handleReportModal}
                    buttonName="Update Guardian Info"
                  /> */}
                </div>
              }
            />
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
                  <Tab label="Personal Information" value="1" />
                  {/* <Tab label="Tuition & Fees" value="2" /> */}
                  <Tab label="Access Control" value="3" />
                  <Tab label="Ward" value="4" />
                </Tabs>
              </div>
              <TabPanel value="1" className="tabpanel-overide">
                <PersonalInfo data={guardiandata} defaultStudents={defaultStudents} multipleStudents={multipleStudents} />
              </TabPanel>
              <TabPanel value="2" className="tabpanel-overide"><TuitionFees /></TabPanel>
              <TabPanel value="3" className="tabpanel-overide"><AccessControl /></TabPanel>
              <TabPanel value="4" className="tabpanel-overide"><Ward /></TabPanel>
            </TabContext>
            <ToastContainer />
          </div>
          {/* Send Report Modal */}
          <Modal
            title="Send Student Report"
            subtitle={
              <div>You are about to share the computed result sheets to the parent/guardian of Ojo William.
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Enter guardian mail"
                      inputType="text"
                    />
                  </Grid>
                  <div className={`${classes.mail} flex align-items-center`}>
                    <Icon icon="fluent:mail-12-filled" width="24" height="24" />
                    <p>Use an alternative email</p>
                  </div>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Send Report" />
                </div>
              </>
            }
            isopen={addreportModal}
            closeModal={handleReportModal}
          />
          {/* Send Broadcast Modal */}
          <Modal
            title="Message Parent/Guardian"
            subtitle={
              <div>Send a message to the parents of the students in this class
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent Name"
                      margin="normal"
                      placeHolder="Parent Name"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Parent Email"
                      inputType="email"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      label="Broadcast Message"
                      margin="normal"
                      placeholder="Enter the message you will like to share with the parents."
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information</label>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        <div>
                          <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                          <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                        </div>
                      </div>
                      <div className={classes.rightpicker}>
                        <div className={classes.uploadbutton}>Upload</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Broadcast" />
                </div>
              </>
            }
            isopen={messageModal}
            closeModal={handleMessageModal}
          />
        </>
      }
    />
  );
};

export default GuardianDetail;
