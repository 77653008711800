import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import PageTitle from "../../../components/PageTitle";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";
import useStyles from "../styles";
import { Icon } from "@iconify/react";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../../data/api";
import useValidator from "../../../hooks/useValidator";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import Badge from "../../../components/Badge";
import ButtonCount from "../../../components/ButtonCount";
import PageLoader from "../../../components/PageLoader";

const EMPLOYEES_ENDPOINT = "/api/v1/employees";
const CLASS_ENDPOINT = "/api/v1/class";

const AllClasses = () => {
  const [searchResult, setSearchResult] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();
  const [addclassModal, setAddClassModal] = useState(false);
  const [editclassModal, setEditClassModal] = useState(false);
  const [classValidator, showClassValidator] = useValidator();
  const [editClassValidator, showEditClassValidator] = useValidator();
  const [deleteModal, setDeleteModal] = useState(false);
  const [showvirtualoption, setShowVirtualOption] = useState(false);
  const [currentSelected, setCurrentSelected] = useState({});
  const [classForm, setClassForm] = useState({
    class_name: "",
    class_teacher_id: "",
    class_type: "",
    meeting_link: "",
    start_time: "",
    end_time: "",
    virtual_class_date: "",
    attendees: []
  });
  const [editClassForm, setEditClassForm] = useState({
    class_name: "",
    class_teacher_id: "",
    class_type: "",
    meeting_link: "",
    start_time: "",
    end_time: "",
    virtual_class_date: "",
    attendees: []
  });
  const [employeeData, setEmployeesData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isClassFetching, setIsClassFetching] = useState(false);
  const [isDeleting, setIsClassDeleting] = useState(false);

  const handleAddClassModal = () => {
    setAddClassModal(!addclassModal);
  };

  const handleEditClassModal = (row) => {
    setEditClassModal(!editclassModal);
    setCurrentSelected(row);
    const { class_name, attendees, class_teacher_id, class_type, end_time, meeting_link, start_time, virtual_class_date } = row;
    setEditClassForm({ ...editClassForm, class_name, attendees,
      class_teacher_id, class_type, end_time, meeting_link, start_time, virtual_class_date });
  };

  const handleCloseEditModal = () => {
    setEditClassModal(false);
  };

  const handleDeleteModal = (row) => {
    setCurrentSelected(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setClassForm({ ...classForm, class_type: value });
    setShowVirtualOption(e.target.value === "virtual");
  };

  const getAllEmployees = () => {
    httprequest.get(EMPLOYEES_ENDPOINT).then((res) => {
      setEmployeesData(res?.data?.data?.employees);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllClasses = () => {
    setIsClassFetching(true);
    httprequest.get(CLASS_ENDPOINT).then((res) => {
      setIsClassFetching(false);
      setClassData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsClassFetching(false);
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(classData, "classData");

  const handleEditClass = () => {
    if (editClassValidator.allValid()) {
      setIsEditLoading(true);
      httprequest.patch(`${CLASS_ENDPOINT}/${currentSelected?.id}`, editClassForm).then((res) => {
        setIsEditLoading(false);
        toast.success(res?.data?.message);
        getAllClasses();
        handleCloseEditModal();
      }).catch((err) => {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showEditClassValidator(true);
    }
  };

  const handleCreateClass = () => {
    if (classValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(CLASS_ENDPOINT, classForm).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAllClasses();
        setClassForm({ ...classForm, class_name: "", class_teacher_id: "", class_type: "", meeting_link: "", start_time: "", end_time: "", virtual_class_date: "", attendees: [] });
        handleAddClassModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showClassValidator(true);
    }
  };

  const handleDeleteClass = () => {
    setIsClassDeleting(true);
    httprequest.delete(`${CLASS_ENDPOINT}/${currentSelected?.id}`).then((res) => {
      setIsClassDeleting(false);
      toast.success(res?.data?.message);
      getAllClasses();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsClassDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const handleClassChange = (e) => {
    const { name, value } = e.target;
    setClassForm({ ...classForm, [name]: value });
  };

  const handleEditClassChange = (e) => {
    const { name, value } = e.target;
    setEditClassForm({ ...editClassForm, [name]: value });
  };
  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    getAllClasses();
    getAllEmployees();
  }, []);

  const columns = [
    {
      name: "Class Name",
      selector: row => row?.class_name
    },
    {
      name: "Assigned Teacher",
      selector: row => row?.class_teacher_name
    },
    {
      name: "No. of Students",
      selector: row => row?.no_of_students
    },
    {
      name: "No of Subjects",
      selector: row => row?.no_of_subjects
    },
    {
      name: "Class Type",
      cell: row => <Badge status={row?.class_type} />
    },
    {
      name: "Date Created",
      selector: row => row?.created_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/class-activities/classes/${row?.id}`}>
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
          <button className="table-action-icon" onClick={() => handleEditClassModal(row)}>
            <Icon icon="lucide:edit" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];
  // --| Filter Classes table using name, email and class
  const handleSearchClasses = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = classData?.filter((data) => valArray?.every(
      (word) => data?.class_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                        data?.class_teacher_name
                          ?.toLowerCase()
                          .includes(word.toLowerCase()) ||
                        data?.class_type
                          ?.toLowerCase()
                          .includes(word.toLowerCase()) ||
                          data?.status
                            ?.toLowerCase()
                            .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const { class_name, class_teacher_id, class_type, meeting_link, start_time, end_time, virtual_class_date } = classForm;

  return (
    <>
      <PageTitle
        title="Classes"
        subtitle="See all the classes currently in your school, can and remove at your convenience."
        button={
          <div className="flex gap-10">
            <div className="flex gap-10">
              <Button onClick={() => {
                navigate("/class-activities/classes/calendar");
              }} variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="View Timetable"
              />
              <Button onClick={handleAddClassModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Create Class" />
            </div>
          </div>
        }
      />
      <div className="page-content">
        {isClassFetching ? (
          <PageLoader />
        ) : (
          <>
            <div className="button-count-container">
              <ButtonCount title="All Created Classes" count={classData?.length} />
            </div>
            <Table data={searchResult} columns={columns} subHeader={true} pagination isLoading={isClassFetching} subHeaderComponent={
              <TableSearch placeholder="Search here..." searchTableFunc={handleSearchClasses} />
            }
            />
          </>
        )}
        {/* Create a Class Modal */}
        <Modal
          title="Create a Class"
          subtitle={
            <div>Create a class and assign a teacher to it
            </div>}
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Class Name"
                    margin="normal"
                    required
                    placeHolder="Enter class name"
                    inputType="text"
                    onChange={handleClassChange}
                    value={class_name}
                    inputName="class_name"
                  />
                  {classValidator.message("class_name", class_name, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectGroup required label="Class Teacher" children={
                    <select onChange={handleClassChange} name="class_teacher_id" value={class_teacher_id}>
                      <option value="">--- Select ---</option>
                      {employeeData?.map((employee) => {
                        return (
                          <option key={employee?.employee_id} value={employee?.employee_id}>{employee?.first_name}{" "}{employee?.last_name}</option>
                        );
                      })}
                    </select>
                  }
                  />
                  {classValidator.message("class_teacher_id", class_teacher_id, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectGroup required label="Class Type" children={
                    <select name="class_type" value={class_type} onChange={handleSelectChange}>
                      <option value="select">--- Select ---</option>
                      <option value="virtual">Virtual</option>
                      <option value="physical">Physical</option>
                    </select>
                  }
                  />
                  {classValidator.message("class_type", class_type, "required")}
                </Grid>
                { showvirtualoption && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="Start Time"
                        margin="normal"
                        required
                        inputType="time"
                        inputName="start_time"
                        value={start_time}
                        onChange={handleClassChange}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="End Time"
                        margin="normal"
                        required
                        inputType="time"
                        inputName="end_time"
                        value={end_time}
                        onChange={handleClassChange}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="Virtual Date"
                        margin="normal"
                        required
                        inputType="date"
                        inputName="virtual_class_date"
                        value={virtual_class_date}
                        onChange={handleClassChange}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="Meeting Link"
                        margin="normal"
                        required
                        inputType="text"
                        inputName="meeting_link"
                        value={meeting_link}
                        onChange={handleClassChange}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Add Email(s)"
                        margin="normal"
                        required
                        placeHolder="Add Emails"
                        inputType="text"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Create Class" onClick={handleCreateClass} isLoading={isLoading} />
              </div>
            </>
          }
          isopen={addclassModal}
          closeModal={handleAddClassModal}
        />

        {/*  Edit Modal */}
        <Modal
          title="Edit a Class"
          subtitle={
            <div>Edit your class</div>}
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Class Name"
                    required
                    margin="normal"
                    placeHolder="Enter class name"
                    inputType="text"
                    onChange={handleEditClassChange}
                    defaultValue={currentSelected?.class_name}
                    inputName="class_name"
                  />
                  {editClassValidator.message("class_name", currentSelected?.class_name, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectGroup required label="Class Teacher" children={
                    <select onChange={handleEditClassChange} name="class_teacher_id" defaultValue={currentSelected?.class_teacher_id}>
                      <option value="">--- Select ---</option>
                      {employeeData?.map((employee) => {
                        return (
                          <option key={employee?.employee_id} value={employee?.employee_id}>{employee?.first_name}{" "}{employee?.last_name}</option>
                        );
                      })}
                    </select>
                  }
                  />
                  {editClassValidator.message("class_teacher_id", currentSelected?.class_teacher_id, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectGroup label="Class Type" children={
                    <select required name="class_type" disabled onChange={handleSelectChange} defaultValue={currentSelected?.class_type}>
                      <option value="select">--- Select ---</option>
                      <option value="virtual">Virtual</option>
                      <option value="physical">Physical</option>
                    </select>
                  }
                  />
                  {editClassValidator.message("class_type", editClassForm?.class_type, "required")}
                </Grid>
                { currentSelected?.class_type === "virtual" && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="Start Time"
                        margin="normal"
                        inputType="time"
                        inputName="start_time"
                        defaultValue={currentSelected?.start_time}
                        onChange={handleEditClassChange}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="End Time"
                        margin="normal"
                        inputType="time"
                        inputName="end_time"
                        defaultValue={currentSelected?.end_time}
                        onChange={handleEditClassChange}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="Virtual Date"
                        margin="normal"
                        inputType="date"
                        inputName="virtual_class_date"
                        defaultValue={currentSelected?.virtual_class_date}
                        onChange={handleEditClassChange}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="Meeting Link"
                        margin="normal"
                        inputType="text"
                        inputName="meeting_link"
                        defaultValue={currentSelected?.virtual_class_date}
                        onChange={handleEditClassChange}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Add Email(s) seperated by commas"
                        margin="normal"
                        placeHolder="Add Emails"
                        inputType="text"
                        inputName="attendees"
                        onChange={handleEditClassChange}
                        defaultValue={currentSelected?.attendees}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Edit Class" onClick={handleEditClass} isLoading={isEditLoading} />
              </div>
            </>
          }
          isopen={editclassModal}
          closeModal={handleCloseEditModal}
        />

        <Modal
          title="Delete your class"
          subtitle={
            <div>Are you sure you want to delete <span className="modal-name-highlight">{currentSelected?.class_name}</span>. Implication is that the students and class teacher wont have access to this class anymore.`
            </div>}
          modalContent={
            <>

            </>
          }
          modalFooter={
            <>
              <Button variant="danger" buttonName="Delete Class" buttonSize="full" color="btndefault" onClick={handleDeleteClass} isLoading={isDeleting} />
            </>
          }
          isopen={deleteModal}
          closeModal={handleCloseDeleteModal}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default AllClasses;
