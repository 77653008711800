/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import useStyles from "./styles";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import ButtonCount from "../../components/ButtonCount";
import PageLoader from "../../components/PageLoader";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Table from "../../components/Table/Table";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import InputGroup from "../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../components/FormComponent/TextAreaGroup";
import { TableSearch } from "../../components/Table/TableActions";
import { Gender } from "../../utils/DropDown";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../data/api";
import useValidator from "../../hooks/useValidator";
import Badge from "../../components/Badge";

// const EMPLOYEES_ENDPOINT = "/api/v1/employees";
const CLASSES_ENDPOINT = "/api/v1/class";
const STUDENT_ENDPOINT = "/api/v1/students";
const UPLOAD_ENDPOINT = "/api/v1/upload";

const Student = () => {
  const [searchResult, setSearchResult] = useState([]);
  const classes = useStyles();
  const [currentRow, setCurrentRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [broadcastModal, setBroadcastModal] = useState(false);
  const [createstudentmodal, setCreateStudentModal] = useState(false);
  const [isStudentsFetching, setIsStudentsFetching] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadForm, setUploadForm] = useState({});
  const [studentData, setStudentsData] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [classData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [studentValidator, showStudentValidation] = useValidator();
  const [activeStudent, setActiveStudent] = useState([]);
  const [searchactiveStudent, setSearchActiveStudent] = useState([]);
  const [searchinactiveStudent, setSearchInActiveStudent] = useState([]);
  const [inactiveStudent, setInActiveStudent] = useState([]);
  const [studentForm, setStudentForm] = useState({
    first_name: "",
    last_name: "",
    admission_date: "",
    class_admitted: "",
    email: "",
    contact: "",
    gender: "",
    age: ""
    // address: ""
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreateStudentModal = () => {
    setCreateStudentModal(!createstudentmodal);
  };

  const handleBroadcastModal = () => {
    setBroadcastModal(!broadcastModal);
  };

  const handleRemoveFile = () => setSelectedFile("");
  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleStudentChange = (e) => {
    const { name, value } = e.target;
    setStudentForm({ ...studentForm, [name]: value });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const getAllClasses = () => {
    httprequest.get(CLASSES_ENDPOINT).then((res) => {
      const physicalClasses = res?.data?.data?.filter((data) => data?.class_type === "physical");
      setClassData(physicalClasses);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllStudents = () => {
    setIsStudentsFetching(true);
    httprequest.get(STUDENT_ENDPOINT).then((res) => {
      setIsStudentsFetching(false);
      setActiveStudent(res?.data?.data?.filter((activeStudent) => activeStudent?.is_active === true));
      setInActiveStudent(res?.data?.data?.filter((inactiveStudent) => inactiveStudent?.is_active === false));
      setStudentsData(res?.data?.data);
      setSearchResult(res?.data?.data);
      setSearchActiveStudent(res?.data?.data?.filter((activeStudent) => activeStudent?.is_active === true));
      setSearchInActiveStudent(res?.data?.data?.filter((inactiveStudent) => inactiveStudent?.is_active === false));
    }).catch((err) => {
      setIsStudentsFetching(false);
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(studentData, "studentData");

  // eslint-disable-next-line no-console
  console.log(activeStudent, "activeStudent");

  const handleCreateStudent = async () => {
    if (studentValidator.allValid()) {
      setIsLoading(true);
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await httprequest.post(
          `${UPLOAD_ENDPOINT}`, formData
        );
        if (response.status === 200 && response.data.data) {
          const upload_id = response.data.data?.upload_id;

          const data = { ...studentForm, upload_id };

          httprequest.post(STUDENT_ENDPOINT, data).then(() => {
            setIsLoading(false);
            toast.success("Student successfully added to the school");
            getAllStudents();
            // --| Reset resource state to original state
            setStudentForm({ ...studentForm, first_name: "", last_name: "", admission_date: "", class_admitted: "",
              email: "", contact: "", guardian: "", gender: "", age: "", address: "" });
            handleCreateStudentModal();
          }).catch((err) => {
            setIsLoading(false);
            toast.error(err?.response?.data.message);
          });
        } else {
          setIsLoading(false);
          toast.error("There is a problem uploading the file");
        }
      } else {
        httprequest.post(STUDENT_ENDPOINT, studentForm).then(() => {
          setIsLoading(false);
          toast.success("Student successfully added to the school");
          getAllStudents();
          // --| Reset resource state to original state
          setStudentForm({ ...studentForm, first_name: "", last_name: "", admission_date: "", class_admitted: "",
            email: "", contact: "", guardian: "", gender: "", age: "", address: "" });
          handleCreateStudentModal();
        }).catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data.message);
        });
      }
    } else {
      showStudentValidation(true);
    }
  };

  // Handle Delete Subject
  const handleDeleteStudent = () => {
    setIsDeleting(true);
    httprequest.delete(`${STUDENT_ENDPOINT}/${currentRow?.student_id}`).then((res) => {
      setIsDeleting(false);
      toast.success(res?.data?.message);
      handleCloseDeleteModal();
      getAllStudents();
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  useEffect(() => {
    // The data will be from the Student get api endpint
    setActiveStudent(studentData);
    getAllStudents();
    getAllClasses();
  }, []);
  const columns = [
    {
      name: "Student Name",
      selector: (row) => row?.first_name + " " + row?.last_name
    },
    {
      name: "Gender",
      selector: (row) => row?.gender || "----------------"
    },
    // {
    //   name: "Status",
    //   center: "true",
    //   cell: (row) => <Badge status={row?.status} />
    // },
    {
      name: "Parent/Guardian",
      selector: (row) => {
        return row?.guardian?.name && row?.guardian?.guardian_id !== null
          ? row.guardian.name
          : "-------------";
      }
    },
    {
      name: "Contact",
      selector: (row) => row?.contact || "-------------"
    },
    {
      name: "Admission Date",
      selector: (row) => row?.admission_date?.substring(0, 10)
    },
    {
      name: "Active",
      cell: (row) => (<Badge status={row?.is_active} />)
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            // onClick={() => {
            //   handleAddscoreModal(row);
            // }}
            className="table-action-icon"
          >
            <Icon icon="ic:outline-folder" />
          </button>
          <Link to={`/users/students/${row?.student_id}`}>
            <button className="table-action-icon">
              <Icon icon="codicon:eye" />
            </button>
          </Link>
          <button onClick={() => handleDeleteModal(row)} className="table-action-icon delete-icon">
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Student table using name, email and class
  const handleSearchStudent = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = activeStudent?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                          data?.first_name
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                          data?.last_name
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                            data?.email
                              ?.toLowerCase()
                              .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
    setInActiveStudent(result);
  };

  // --| Filter Active Student table using name, email and class
  const handleSearchActiveStudent = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = studentData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                          data?.first_name
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                          data?.last_name
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                            data?.email
                              ?.toLowerCase()
                              .includes(word.toLowerCase())
    )
    );
    setSearchActiveStudent(result);
  };

  // --| Filter Active Student table using name, email and class
  const handleSearchInActiveStudent = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = inactiveStudent?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                          data?.first_name
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                          data?.last_name
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                            data?.email
                              ?.toLowerCase()
                              .includes(word.toLowerCase())
    )
    );
    setSearchInActiveStudent(result);
  };

  const { first_name, last_name, admission_date, class_admitted, email, contact,
    gender, age } = studentForm;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="All Students"
            subtitle="See the details of the students in your school"
            button={
              <div className="flex gap-10">
                {/* <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  onClick={handleBroadcastModal}
                  buttonName="Send Broadcast to parents"
                /> */}
                <Button
                  variant="primary"
                  buttonSize="small"
                  color="btndefault"
                  onClick={handleCreateStudentModal}
                  buttonName="Create Students"
                />
              </div>
            }
          />
          <div className={classes.pageContent}>
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="All" value="" />
                  <Tab label="Active" value="active" />
                  <Tab label="Inactive" value="inactive" />
                  {/* <Tab label="Terminated Staff" value="terminated" /> */}
                </Tabs>
              </div>
              {isStudentsFetching ? (
                <PageLoader />
              ) : (
                <>
                  <TabPanel value="" className="tabpanel-overide">
                    <Table
                      data={searchResult}
                      columns={columns}
                      subHeader={true}
                      pagination
                      isLoading={isStudentsFetching}
                      subHeaderComponent={
                        <TableSearch
                          placeholder="Search here..."
                          searchTableFunc={handleSearchStudent}
                        />
                      }
                    />
                    {/* Teachers data={employeesData?.filter(active => active?.is_active === true)} */}
                  </TabPanel>
                  <TabPanel value="active" className="tabpanel-overide">
                    <Table
                      data={searchactiveStudent}
                      columns={columns}
                      subHeader={true}
                      pagination
                      isLoading={isStudentsFetching}
                      subHeaderComponent={
                        <TableSearch
                          placeholder="Search here..."
                          searchTableFunc={handleSearchActiveStudent}
                        />
                      }
                    />
                  </TabPanel>
                  <TabPanel value="inactive" className="tabpanel-overide">
                    <Table
                      data={searchinactiveStudent}
                      columns={columns}
                      subHeader={true}
                      pagination
                      isLoading={isStudentsFetching}
                      subHeaderComponent={
                        <TableSearch
                          placeholder="Search here..."
                          searchTableFunc={handleSearchInActiveStudent}
                        />
                      }
                    />
                  </TabPanel>
                </>
              )}
            </TabContext>
            <div className={classes.buttoncountcontainer}>
              <ButtonCount title="Total Student Count" count={value === "" ? studentData?.length : value === "active" ? activeStudent?.length : inactiveStudent?.length} />
            </div>
          </div>
          {/* Submit Examination Modal */}
          <Modal
            title="Add a Student to your school."
            subtitle={
              <div> Add a student to your school, it sends an invite email to them which gives them access to login on their app
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="First Name"
                      required
                      margin="normal"
                      inputType="text"
                      inputName="first_name"
                      value={first_name}
                      onChange={handleStudentChange}
                    />
                    {studentValidator.message("First Name", first_name, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      required
                      label="Last Name"
                      margin="normal"
                      inputType="text"
                      inputName="last_name"
                      value={last_name}
                      onChange={handleStudentChange}
                    />
                    {studentValidator.message("Last Name", last_name, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Student Email"
                      required
                      margin="normal"
                      placeHolder="Enter email"
                      inputType="email"
                      inputName="email"
                      value={email}
                      onChange={handleStudentChange}
                    />
                    {studentValidator.message("Email", email, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Age"
                      required
                      margin="normal"
                      inputType="number"
                      inputName="age"
                      value={age}
                      onChange={handleStudentChange}
                    />
                    {studentValidator.message("Age", age, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Student Contact"
                      required
                      margin="normal"
                      inputType="tel"
                      inputName="contact"
                      value={contact}
                      onChange={handleStudentChange}
                    />
                    {studentValidator.message("Contact", contact, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Admission Date"
                      required
                      margin="normal"
                      inputType="date"
                      inputName="admission_date"
                      value={admission_date}
                      onChange={handleStudentChange}
                    />
                    {studentValidator.message("admission date", admission_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup required label="Class Admitted to" children={
                      <select value={class_admitted} name="class_admitted" onChange={handleStudentChange}>
                        <option value="">---Select Labels ---</option>
                        {classData?.map((data) => {
                          return (
                            <option key={data?.class_id} value={data?.id}>{data?.class_name}</option>
                          );
                        })}
                      </select>
                    }
                    />
                    {studentValidator.message("class admitted", class_admitted, "required")}
                  </Grid>
                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Address"
                      margin="normal"
                      inputType="text"
                      inputName="address"
                      value={address}
                      onChange={handleStudentChange}
                    />
                  </Grid> */}
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup required label="Gender" children={
                      <select value={gender} name="gender" onChange={handleStudentChange}>
                        <option value="">---Select  ---</option>
                        {Gender?.map((gend) => {
                          return (
                            <option key={gend?.key} value={gend?.key}>{gend?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                    {studentValidator.message("Gender", gender, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Upload supporting documents<span className="input-required">*</span> </label>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept=".pdf" />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        {selectedFile?.name ? (
                          <div>
                            <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
                          </div>
                        ) :
                          <div>
                            <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                            <h6 className={classes.uploadsubtitle}>PDF| 10MB max.
                            </h6>
                          </div>}
                      </div>
                      <div className={classes.rightpicker}>
                        {selectedFile?.name ? (
                          <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                        ) : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Add Student" onClick={handleCreateStudent} isLoading={isLoading} />
                </div>
              </>
            }
            isopen={createstudentmodal}
            closeModal={handleCreateStudentModal}
          />
          {/* Send Broadcast Modal */}
          <Modal
            title="Send Broadcast to parents."
            subtitle={
              <div>Send a broadcast message to the parents of the students in this class
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Broadcast To:"
                      required
                      margin="normal"
                      placeHolder="Parent Email"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      label="Broadcast Message"
                      required
                      margin="normal"
                      placeholder="Enter the message you will like to share with the parents."
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information <span className="input-required">*</span></label>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        <div>
                          <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                          <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                        </div>
                      </div>
                      <div className={classes.rightpicker}>
                        <div className={classes.uploadbutton}>Upload</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Broadcast" />
                </div>
              </>
            }
            isopen={broadcastModal}
            closeModal={handleBroadcastModal}
          />
          {/* Delete Modal */}
          <Modal
            title="Delete Student Information"
            subtitle={
              <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.first_name + " " + currentRow?.last_name}</span>. Deleting is a permanent action`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <Button variant="danger" buttonName="Delete Student Information" isLoading={isDeleting} onClick={handleDeleteStudent} buttonSize="full" color="btndefault" />
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default Student;
