import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  Outlet
} from "react-router-dom";

// --| Styles
import "./styles/App.css";
import "./styles/color.css";
import "./pages/auth/auth.css";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import themes from "./themes";

import Error from "./pages/error/Error";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import StudentEnrol from "./pages/studentenrolment";
import UserSignUp from "./pages/auth/user/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import AuthVerification from "./pages/auth/AuthVerification";
import AdminClassDetails from "./pages/classes/admin/ClassDetails";
import Classes from "./pages/classes/index";
import StudentInfo from "./pages/classes/user/StudentInfo";
import Examination from "./pages/examination/index";
import Student from "./pages/student/Student";
import JSSOneExamination from "./pages/examination/employer/JuniorSecSch";
import EmployeeEmployeeJSSOne from "./pages/examination/employee/EmployeeJnrSec";
import ExamPaperReview from "./pages/examination/employer/ExamPaperReview";
import EmployeeExamReview from "./pages/examination/employee/EmployeeExamPaperReview";
import Inventory from "./pages/inventory/Inventory";
import Guardian from "./pages/guardian/Guardian";
import GuardianDetail from "./pages/guardian/GuardianDetail";
import ChildTimeTable from "./pages/guardian/components/Timetable";
import EditInventory from "./pages/inventory/EditInventory";
import ArchivedInventory from "./pages/inventory/ArchivedInventory";
import CreateInventory from "./pages/inventory/CreateInventory";
import LeaveMgt from "./pages/leavemgt/LeaveMgt";
import LeaveMgtDetails from "./pages/leavemgt/LeaveMgtDetails";
import LeaveOption from "./pages/leavemgt/LeaveOptions";
import LeaveOptionDetails from "./pages/leavemgt/LeaveOptionDetails";
import Integration from "./pages/integration";
import WelcomeToShelf from "./pages/onboarding/WelcomeToShelf";
import PerfEval from "./pages/perfeval/PervEval";
import GoalCategory from "./pages/perfeval/GoalCategory";
import Procurement from "./pages/procurement/Procurement";
import Contractors from "./pages/procurement/Contractors";
import ProcurementMenu from "./pages/procurement/procurementmenu";
import SchoolPlans from "./pages/schoolplans/index";
import SchoolSession from "./pages/schoolplans/Employer/SchoolSession";
import SchoolTerm from "./pages/schoolplans/Employer/SchoolTerm";
import EmployeeSchCalendar from "./pages/schoolplans/Employee/EmployeeSchoolCalendar";
import ViewSchoolPlans from "./pages/schoolplans/Employer/ViewSchoolPlans";
import SchoolCalendar from "./pages/schoolplans/Employer/SchoolCalendar";
import Resources from "./pages/resources/Resources";
import Resource from "./pages/resources/Resource";
import ResourceLabel from "./pages/resources/ResourceLabel";
import Settings from "./pages/settings/index";
import ApiUsage from "./pages/settings/components/ApiUsage";
import Subjects from "./pages/subjects/index";
import AdminAssignment from "./pages/assignment/admin/Assignment";
import AssignmentDetail from "./pages/assignment/components/AssignmentDetail";
import AssignmentSubmission from "./pages/assignment/admin/AssignmentSubmission";
// import Assignments from "./pages/subjects/components/ForEmployee/Assignments";
import CreateAssignment from "./pages/assignment/components/CreateAssignment";
import Employees from "./pages/employees";
import Employee from "./pages/employees/EmployeeDetail";
import Enrolments from "./pages/enrolments";
import Applications from "./pages/enrolments/Applications";
import Welfare from "./pages/welfare/Welfare";
import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/index";
import CreateClass from "./pages/classes/admin/AllClasses";
import EmployeeCreateClass from "./pages/classes/user/EmployeeCreateClass";
import EmployeeAllClasses from "./pages/classes/user/EmployeeAllClasses";
import ClassesCalendar from "./pages/classes/admin/AllClassesCalendar";
import StudentReport from "./pages/classes/user/StudentReport";
import InventoryCategory from "./pages/inventory/InventoryCategory";
import Permission from "./pages/error/Permission";
import Events from "./pages/events/index";
import EventDetails from "./pages/events/admin/EventDetails";
import SitemMap from "./pages/sitemap/SiteMap";
import AccessActivities from "./pages/acesscontrol/index";
import AccessCodes from "./pages/acesscontrol/admin/AccessCodes";
import ValidateCode from "./pages/acesscontrol/component/ValidateCode";
import AccessInfo from "./pages/acesscontrol/component/AccessInfo";
import Library from "./pages/library/index";
import NotificationDetails from "./pages/notification/NotificationDetails";
import AllNotification from "./pages/notification/AllNotification";
import StudentApplication from "./pages/studentapplication/Studentoverview";
import LessonNote from "./pages/lessonnote/index";
import ViewLessonNote from "./pages/lessonnote/component/ViewLessonNote";
import ViewSubjectNote from "./pages/lessonnote/component/ViewSubjectLessonNote";
import CreateLessonNote from "./pages/lessonnote/component/CreateLessonNote";
import EditLessonNote from "./pages/lessonnote/component/EditLessonNote";
import LessonNoteDraft from "./pages/lessonnote/component/LessonNoteDraft";
import Attendance from "./pages/attendance/index";
import EmployeeLeaveCalendar from "./pages/leavemgt/components/EmployeeLeaveCalendar";
import SubjectDetails from "./pages/subjects/student/component/SubjectDetails";
import StudentSyllabus from "./pages/syllabus/index";
import SyllabusDetails from "./pages/syllabus/student/component/SyllabusDetails";
import AdminCalendar from "./pages/leavemgt/components/AdminCalendar";
import LeaveCalendar from "./pages/welfare/components/LeaveCalendar";
import TimeTable from "./pages/timetable/index";
import ViewEnrolment from "./pages/enrolments/ViewEnrolment";
import AdminTimeTableCalendar from "./pages/timetable/admin/component/TimeTableCalendar";
// import SubjectTimeTable from "./pages/timetable/admin/component/SubjectTimeTableInfo";
import EditTimeTable from "./pages/timetable/admin/component/EditTimeTable";
import ViewLibraryItem from "./pages/library/component/ViewLibraryBook";
import AdminNoticeBoard from "./pages/noticeboard/admin/AdminNoticeBoard";

// context
import { useUserState } from "./context/UserContext";
import PurchaseOrder from "./pages/procurement/PurchaseOrder";
// import Enrolments from "./pages/studentapplications/Enrolments";
import EnrolmentDetails from "./pages/enrolments/EnrolmentsDetails";
import ControlCenter from "./pages/acesscontrol/admin/ControlCenter";
import ApplicantLogin from "./pages/studentapplication/ApplicantLogin";
import EntranceExamination from "./pages/studentapplication/entranceexamination/EntranceExamination";
import ClassCalendar from "./pages/classes/components/ClassCalendar";
import Assessments from "./pages/assessment/Index";
import CreateEnrolments from "./pages/enrolments/CreateEnrolments";
import Success from "./pages/studentenrolment/Success";
import Failed from "./pages/studentenrolment/Failed";
import Enrolmentpreview from "./pages/enrolments/Enrolmentpreview";
import ApplicationDetails from "./pages/enrolments/ApplicationDetails";
import EditEnrolment from "./pages/enrolments/EditEnrolment";
const App = () => {
  // global
  const { isAuthenticated, appColor } = useUserState();
  const theme = appColor ? themes(appColor) : themes("#0065FF");
  // const isApplicantAuthenticated = localStorage.getItem("enrol-auth");
  // // eslint-disable-next-line no-console
  // console.log("isAp", isApplicantAuthenticated);

  const AuthenticatedRoute = () => {
    return isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" />;
  };

  // const AuthenticatedApplicant = () => {
  //   return (
  //     !isApplicantAuthenticated && <Navigate to="/auth/applicant" />
  //   );
  // };

  return (
    <div className="App">
      <ThemeProvider theme={theme.default}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            {/* This Redirect root URL based on authentication */}
            <Route
              path="/"
              element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/auth/login" />}
            />
            {/* Authenticated Routes */}
            <Route path="/" element={<AuthenticatedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/assesments/student/grades"
                element={<Assessments />}
              />
              <Route path="/class-activities/classes" element={<Classes />} />
              <Route
                path="/class-activities/classes/:id"
                element={<AdminClassDetails />}
              />
              <Route
                path="/class-activities/classes/createclass"
                element={<CreateClass />}
              />
              <Route
                path="/class-activities/classes/employeecreateclass"
                element={<EmployeeCreateClass />}
              />
              <Route
                path="/class-activities/classes/employeecreateclass/studentreport"
                element={<StudentReport />}
              />
              <Route
                path="/class-activities/classes/calendar"
                element={<ClassesCalendar />}
              />
              <Route
                path="/class-activities/classes/createclass/allclasses"
                element={<EmployeeAllClasses />}
              />
              <Route
                path="/class-activities/classes/students/:id"
                element={<StudentInfo />}
              />
              <Route
                path="/class-activities/classes/class-calendar"
                element={<ClassCalendar />}
              />
              <Route path="/assesments/examination" element={<Examination />} />
              <Route
                path="/assesments/examination/jssone-examination-papers"
                element={<JSSOneExamination />}
              />
              <Route
                path="/assesments/examination/employee-jssone"
                element={<EmployeeEmployeeJSSOne />}
              />
              <Route
                path="/assesments/examination/jssone-examination-papers/exam-paper-review"
                element={<ExamPaperReview />}
              />
              <Route
                path="/assesments/examination/jssone-examination-papers/employee-exam-paper-review"
                element={<EmployeeExamReview />}
              />
              <Route
                path="/notifications/:id"
                element={<NotificationDetails />}
              />
              <Route path="/notifications" element={<AllNotification />} />
              <Route path="/users/students" element={<Student />} />
              <Route path="/users/students/:id" element={<StudentInfo />} />
              <Route path="/users/guardians" element={<Guardian />} />
              <Route path="/users/guardians/:id" element={<GuardianDetail />} />
              <Route
                path="/users/guardians/child-timetable"
                element={<ChildTimeTable />}
              />
              <Route path="/manage/inventory" element={<Inventory />} />
              <Route path="/manage/inventory/:id" element={<EditInventory />} />
              <Route
                path="/manage/inventory/create"
                element={<CreateInventory />}
              />
              <Route
                path="/manage/inventory/categories"
                element={<InventoryCategory />}
              />
              <Route
                path="/manage/inventory/archive-inventory"
                element={<ArchivedInventory />}
              />
              <Route path="/manage/leave" element={<LeaveMgt />} />
              <Route path="/enrolments" element={<Enrolments />} />
              <Route path="/enrolments/:id" element={<EnrolmentDetails />} />
              <Route
                path="/enrolments/applications"
                element={<Applications />}
              />
              <Route path="/enrolments/create" element={<CreateEnrolments />} />
              <Route
                path="/enrolments/viewenrolments"
                element={<ViewEnrolment />}
              />
              {/* <Route path="/applications/enrolments" element={<Enrolments />} /> */}
              <Route path="/attendance" element={<Attendance />} />
              <Route path="/manage/leave/:id" element={<LeaveMgtDetails />} />
              <Route
                path="/manage/leave/team-calendar"
                element={<AdminCalendar />}
              />
              <Route
                path="/manage/leave/leave-calendar/:id"
                element={<EmployeeLeaveCalendar />}
              />
              <Route path="/manage/leaveoptions" element={<LeaveOption />} />
              <Route
                path="/manage/leaveoptions/:id"
                element={<LeaveOptionDetails />}
              />
              <Route path="/integrations" element={<Integration />} />
              <Route path="/users/employees" element={<Employees />} />
              <Route path="/users/employees/:id" element={<Employee />} />
              <Route path="/welfare" element={<Welfare />} />
              <Route path="/leave/team-calendar" element={<LeaveCalendar />} />
              <Route path="/library" element={<Library />} />
              <Route path="/library/:id" element={<ViewLibraryItem />} />
              <Route path="/time-table" element={<TimeTable />} />
              <Route
                path="/time-table/subject-timetable"
                element={<EditTimeTable />}
              />
              <Route
                path="/timetable/admin-calendar"
                element={<AdminTimeTableCalendar />}
              />
              <Route path="/lesson-notes" element={<LessonNote />} />
              <Route path="/lesson-notes/:id" element={<ViewLessonNote />} />
              <Route
                path="/lesson-notes/create-lesson-note"
                element={<CreateLessonNote />}
              />
              <Route
                path="/lesson-notes/edit-lesson-outline/:id"
                element={<EditLessonNote />}
              />
              <Route
                path="/lesson-notes/lesson-outlines/:id"
                element={<ViewSubjectNote />}
              />
              <Route
                path="/lesson-notes/drafts/:id"
                element={<LessonNoteDraft />}
              />
              <Route
                path="/accesscontrol/logs"
                element={<AccessActivities />}
              />
              <Route path="/accesscontrol/codes" element={<AccessCodes />} />
              <Route
                path="/accesscontrol/validate"
                element={<ValidateCode />}
              />
              <Route
                path="/accesscontrol/codes/create"
                element={<ControlCenter />}
              />
              <Route path="/accesscontrol/codes/:id" element={<AccessInfo />} />
              <Route path="/events" element={<Events />} />
              <Route path="/events/:id" element={<EventDetails />} />
              <Route path="/manage/goals" element={<PerfEval />} />
              <Route path="/site-map" element={<SitemMap />} />
              <Route path="/manage/notice-board" element={<AdminNoticeBoard />} />
              <Route path="/manage/goal-category" element={<GoalCategory />} />
              <Route path="/manage/procurements" element={<Procurement />} />
              <Route
                path="/manage/procurements/new"
                element={<PurchaseOrder />}
              />
              <Route
                path="/manage/procurements/contractors"
                element={<Contractors />}
              />
              <Route
                path="/manage/procurements/ProcurementMenu"
                element={<ProcurementMenu />}
              />
              <Route path="/schoolplans" element={<SchoolPlans />} />
              <Route path="/schoolsession" element={<SchoolSession />} />
              <Route path="/schoolterm" element={<SchoolTerm />} />
              <Route
                path="/schoolplans/employee-school-plan"
                element={<EmployeeSchCalendar />}
              />
              <Route
                path="/schoolplans/view-school-plans"
                element={<ViewSchoolPlans />}
              />
              <Route
                path="/schoolplans/view-school-plans/school-calender"
                element={<SchoolCalendar />}
              />
              <Route path="/manage/knowledgebase" element={<Resources />} />
              <Route path="/manage/knowledgebase/:id" element={<Resource />} />
              <Route
                path="/manage/knowledgebase/labels"
                element={<ResourceLabel />}
              />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/:id" element={<ApiUsage />} />
              <Route
                path="/assesments/assignments"
                element={<AdminAssignment />}
              />
              <Route
                path="/assesments/assignments/:id"
                element={<AssignmentDetail />}
              />
              <Route
                path="/assesments/assignment/submission/:id"
                element={<AssignmentSubmission />}
              />
              <Route path="/class-activities/subjects" element={<Subjects />} />
              <Route
                path="/class-activities/subjects/:id"
                element={<SubjectDetails />}
              />
              <Route
                path="/assesments/assignment/create"
                element={<CreateAssignment />}
              />
              <Route
                path="/class-activities/syllabus"
                element={<StudentSyllabus />}
              />
              <Route
                path="/class-activities/syllabus/:id"
                element={<SyllabusDetails />}
              />
              {/* <Route path="/subjects/assignment/:id" element={<Assignments />} /> */}
              {/* The Route Below is used to navigate to Subject (Mathematics, e.t.c) */}
              <Route path="/onboarding" element={<WelcomeToShelf />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
            {/* Student Applications Login */}
            <Route path="/auth/applicant" element={<ApplicantLogin />} />
            <Route
              path="/student-application"
              element={<StudentApplication />}
            />
            <Route path="/enrolment-preview/:id" element={<Enrolmentpreview />} />
            <Route path="/enrolments/applications/:id" element={<ApplicationDetails />} />
            <Route path="/enrolments/edit/:id" element={<EditEnrolment />} />
            <Route
              path="/entrance-examination/:id"
              element={<EntranceExamination />}
            />
            <Route path="/enrol/:id" element={<StudentEnrol />} />
            <Route path="/payment-response" element={<Success />} />
            <Route path="payfailed=" element={<Failed />} />

            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/signup" element={<Signup />} />
            <Route path="/auth/user-signup/:id" element={<UserSignUp />} />
            <Route path="/not-allowed" element={<Permission />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/verify/:id" element={<AuthVerification />} />
            <Route path="/auth/reset/:id" element={<ResetPassword />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
